import React, { useState, useRef, useEffect } from "react";
import { Button } from "../UI/Buttons/ButtonStyle1/Button";
import { ServicesObj } from "../Data";
import {
  ServicesContainer,
  ServicesWrapper,
  ServicesRow,
  Column1,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  Column2,
  ImageWrap,
  Img,
} from "./ServicesStyles";

const Services = () => {
  const {
    lightBg,
    id,
    imgStart,
    topLine,
    lightText,
    headLine,
    darkText,
    description,
    buttonLabel,
    img,
    alt,
    primary,
    dark,
    dark2,
  } = ServicesObj;
  const heroEle = useRef();

  useEffect(() => {
    const getHeight = getPosition(heroEle.current);
    console.log(heroEle.current.offsetTop, "Effects");
  }, []);

  function getPosition(element) {
    var xPosition = 0;
    var yPosition = 0;

    while (element) {
      xPosition += element.offsetLeft - element.scrollLeft + element.clientLeft;
      yPosition += element.offsetTop - element.scrollTop + element.clientTop;
      element = element.offsetParent;
    }

    return { x: xPosition, y: yPosition };
  }
  return (
    <>
      <ServicesContainer ref={heroEle} lightBg={lightBg} id={id}>
        <ServicesWrapper>
          <ServicesRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>Sorry! This section is under construction.</TopLine>
                <Heading lightText={lightText}>Sorry! This section is under construction.</Heading>
                {/* <Subtitle darkText={darkText}>{description}</Subtitle> */}
              </TextWrapper>
            </Column1>
            <Column2>
              <ImageWrap>
                <Img src={img} alt={alt} />
              </ImageWrap>
            </Column2>
          </ServicesRow>
        </ServicesWrapper>
      </ServicesContainer>
    </>
  );
};

export default Services;
