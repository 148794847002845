import styled from "styled-components";

export const FooterElement = styled.div`
  /* background: black; */
  height: 30px;
  /* margin-top: -80px; */
  display: grid;
  grid-template-columns: repeat(60, 1fr);
  /* justify-content: center; */
  align-items: center;
  font-size: 14px;
  position: fixed;
  grid-gap: 1px;
  column-gap: 12px;
  padding-left: 60px;
  width: 100%;
  top: 94vh;
  z-index: 10;
text-rendering: optimizeLegibility;
text-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  
  letter-spacing: 0.1em;
  font-weight: 400;
  
  font-family: 'Open Sans Condensed', sans-serif;
  /* font-family: "Bebas Neue", sans-serif; */
  text-transform: uppercase;
`;

export const CurrentDate = styled.div`
  color: white; 
  text-align: center;
  grid-column-start: 1;
  grid-column-end: 2;
  width: 90px;
`;

export const Signature = styled.div`
  background: #ffffff;
  /* margin-left: 20px; */
  padding-left: 7px;
  grid-column-start: 2;
  grid-column-end: 3;
color: #0d0d0d;
font-weight: bold;
width: 180px;
-webkit-transition: 0.2s;
transition: 0.2s;
`;

export const Facebook = styled.div`
  background: transparent;
color: white;
grid-column-start: 48;
  grid-column-end: 54;
font-weight: bold;
-webkit-transition: 0.2s;
transition: 0.2s;
`;


export const Contact = styled.div`
  background: transparent;
color: white;
  grid-column-start: 54;
  grid-column-end: 55;
font-weight: bold;
-webkit-transition: 0.2s;
transition: 0.2s;
`;
