import React, { useState, useRef, useEffect, useCallback } from "react";
import { FaBars } from "react-icons/fa";
import logo from "../../../pics/siuex-official-nobg.png";
import { animateScroll as scroll } from "react-scroll";
import NavBarItem from "./NavBarItem";
import { shallowEqual, useDispatch } from "react-redux";
import ButtonCool from "../ButtonCool/ButtonCool";
import {
  Nav as NavBar,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavLogoImg,
} from "./NavBarStyledElements";
const Nav = (props) => {
  const [elementSelected, setElementSelected] = useState("hero");
  const dispatch = useDispatch();
  const getActivated = useRef();
  const [currentStateStatus, setCurrentStateStatus] = useState("");
  const [currentStateStatusId, setCurrentStateStatusId] = useState("");

  const toggleScrollToTopHandler = () => {
    scroll.scrollToTop();
  };

  const onClickHeroHandler = (value) => {
    setElementSelected("hero");
    // setCurrentStateStatus(currentStateStatus.state.active);
    console.log(currentStateStatus, "curr");
  };

  const onClickProductsHandler = (value) => {
    setElementSelected("products");

    console.log(currentStateStatus, "curr");
  };
  const onClickServicesHandler = (value) => {
    setElementSelected("services");
    console.log(currentStateStatus, "curr");
  };
  const onClickAboutHandler = (value) => {
    setElementSelected("about");
    console.log(currentStateStatus, "curr");
  };
  const onClickProjectsHandler = (value) => {
    setElementSelected("projects");
    console.log(currentStateStatus, "curr");
  };
  const onClickContactHandler = (value) => {
    setElementSelected("contact");
    console.log(currentStateStatus, "curr");
  };

  // dispatch({ type: "change", currentElement: elementSelected });

  const getCurrentHeroRef = (curr) => {
    if (curr.state.active === true) {
      setCurrentStateStatus(curr.state.active);
      setCurrentStateStatusId(curr.state.active);
    }
  };
  const getCurrentProductsRef = (curr) => {
    if (curr.state.active === true) {
      setCurrentStateStatus(curr.state.active);
      setCurrentStateStatusId(curr.state.active);
    }
  };
  const getCurrentServicesRef = (curr) => {
    if (curr.state.active === true) {
      setCurrentStateStatus(curr.state.active);
      setCurrentStateStatusId(curr.state.active);
    }
  };
  const getCurrentProjectsRef = (curr) => {
    if (curr.state.active === true) {
      setCurrentStateStatus(curr.state.active);
      setCurrentStateStatusId(curr.state.active);
    }
  };
  const getCurrentAboutRef = (curr) => {
    if (curr.state.active === true) {
      setCurrentStateStatus(curr.state.active);
      setCurrentStateStatusId(curr.state.active);
    }
  };
  const getCurrentContactRef = (curr) => {
    if (curr.state.active === true) {
      setCurrentStateStatus(curr.state.active);
      setCurrentStateStatusId(curr.state.active);
    }
  };

  // props.activeElement(elementSelected);
  return (
    <>
      <NavBar>
        <NavbarContainer>
          <NavLogo to="/" onClick={toggleScrollToTopHandler}>
            <NavLogoImg src={logo} />
          </NavLogo>
          <MobileIcon onClick={props.toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavBarItem
              ref={getActivated}
              onClick={onClickHeroHandler}
              getCurrentRef={getCurrentHeroRef}
              id={"hero"}
              navText="home"
              offset={-100}
              key="hero"
            />
            <NavBarItem
              onClick={onClickProductsHandler}
              getCurrentRef={getCurrentProductsRef}
              id={"products"}
              navText="products"
              offset={-70}
              key="products"
            />
            <NavBarItem
              onClick={onClickServicesHandler}
              getCurrentRef={getCurrentServicesRef}
              id={"services"}
              navText="services"
              offset={0}
              key="services"
            />
            <NavBarItem
              onClick={onClickAboutHandler}
              getCurrentRef={getCurrentAboutRef}
              id={"about"}
              navText="about"
              offset={0}
              key="about"
            />
            <NavBarItem
              onClick={onClickProjectsHandler}
              getCurrentRef={getCurrentProjectsRef}
              id={"projects"}
              navText="projects"
              offset={0}
              key="projects"
            />
            <NavBarItem
              onClick={onClickContactHandler}
              getCurrentRef={getCurrentContactRef}
              id={"contact"}
              navText="contact"
              offset={0}
              key="contact"
            />
          </NavMenu>
          {/* <NavBtn>
            <NavBtnLink to="/signIn">Sign In</NavBtnLink>
          </NavBtn> */}
        </NavbarContainer>
      </NavBar>
    </>
  );
};

export default Nav;
