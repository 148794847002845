import React from "react";
// import Slider from "../UI/SlideComponent/Slider";
import ProductsSlider from "./ProductsSlider";
// import SlideShowTop from "../SlideShowTop";
// import { Button } from "../UI/Buttons/ButtonStyle1/Button";
import { ProductsObj } from "../Data";
import Data from "../../data/data";
import {
  ProductsContainer,
  SliderWrapper,
} from "./ProductsStyles";

const Products = () => {
  const data= Data.properties;
  const {
    id,
  } = ProductsObj;
  return (
    <ProductsContainer id={id}>
      <SliderWrapper>
        <ProductsSlider slides={data} />
      </SliderWrapper>
    </ProductsContainer>
  );
};

export default Products;
