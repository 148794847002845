const data = {
  properties: [
    {
      _id: "593e9297e17df20c4a237d42",
      index: 0,

      picture: "office.jpg",
      title: "Customized Solutions",
      overview:
        "Bring Your Business or Personal Ideas To Life, Build a Web or Android App With a Professional by Your Side to Bring Out The Best of Your Product ",

      textColor: "#CEE7FF",
      navColor: "white",
    },
    {
      _id: "593e9297ec4cca9c56bf61af",
      index: 1,

      picture: "pexels-pixabay.jpg",
      // picture: "https://images.indianexpress.com/2021/03/Gaming-Laptops-1.jpg",
      title: "Only The Best",
      overview:
        "We Are Dedicated In Offering The Best Valued Services For Your Time And Money",

      textColor: "white",
      navColor: "#CEE7FF",
      // navColor: "#ff9400",
    },
    {
      _id: "593e929773c71925e5d7c11c",
      index: 2,

      picture: "pexels-luis-gomes.jpg",
      // picture:"https://www.elegantthemes.com/blog/wp-content/uploads/2016/03/high-quality-wordpress-themes.png",
      title: "",
      overview:
        "Bring Your Business or Personal Ideas To Life, Build a Web or Android App With a Professional by Your Side to Bring Out The Best of Your Product",

      textColor: "#ff9400;",
      navColor: "white",
    },
    {
      _id: "593e92973d4d15eedb129aee",
      index: 3,

      picture: "researchimage.jpg",
      // picture: "https://windowsthemepack.com/themepack/_cool_themes/artificial-intelligence/26.jpg",
      title: "AI Solutions",
      overview:
        "We offer computer vision implementations using deep learning models.",

      textColor: "white",
      navColor: "#ff9400",
    },
  ],
};

export default data;
