import React, { useState, useRef, useImperativeHandle, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { Link as LinkS } from "react-scroll";
import { useDispatch } from "react-redux";
import {
  NavItem,
  NavText,
  SvgWrapper,
  Svg,
  Rect,
} from "./NavBarStyledElements";
const NavBarItem = React.forwardRef((props, ref) => {
  const activeRef = useRef();

  const dispatch = useDispatch();

  // useImperativeHandle(ref,() => {
  //   // console.log(activeRef.current,'activeRef')
  //   // console.log(activeRef,'activeRef')
  //   return {
  //     activated: returnActivated,
  //   };
  // });

  useEffect(() => {
    const interval = setInterval(() => {
    
      if (activeRef.current.state.active) {
        dispatch({
          type: "change",
          currentElement: activeRef.current.props.to,
        });
      }
      // console.log(currentStateStatus, "curr");
    }, 5);
    return () => clearInterval(interval);
  }, []);

  // useEffect(()=>{
  //   props.getCurrentRef(activeRef.current);
  // })

  return (
    <>
      <NavItem
        to={props.id}
        smooth={true}
        duration={500}
        spy={true}
        exact="true"
        offset={props.offset}
        onClick={props.onClick}
      >
        <SvgWrapper>
          <Svg xmlnsXlink="http://www.w3.org/2000/svg">
            <Rect />
          </Svg>
          {/* <input ref={setval} value={currentStateStatus}></input> */}
          <NavText
            to={props.id}
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={props.offset}
            ref={activeRef}
            onClick={props.onClick}
          >
            {props.navText}
          </NavText>
        </SvgWrapper>
      </NavItem>
    </>
  );
});

export default NavBarItem;
