import React, { useState, useEffect } from "react";
import "./Menu.css";
import { Wrap } from "./MenuStyles";
import { Link as LinkS, scroller } from "react-scroll";
import NavBarItem from "../Nav/NavBarItem";
import { useSelector } from "react-redux";

const Menu = () => {
  var activeHero = false;
  var activeProducts = false;
  var activeServices = false;
  var activeAbout = false;
  var activeProjects = false;
  var activeContact = false;
  // var [activeServices, setActiveServices] = useState(false);
  // var [activeAbout, setActiveAbout] = useState(false);
  // var [activeProjects, setActiveProjects] = useState(false);
  // var [activeContact, setActiveContact] = useState(false);


  const currentElement = useSelector((state) => state.currentElement);

  switch (currentElement) {
    case "hero":
      activeHero = true;
      break;
    case "products":
      activeProducts = true;
      break;
    case "services":
      activeServices = true;
      break;
    case "about":
      activeAbout = true;
      break;
    case "projects":
      activeProjects = true;
      break;
    case "contact":
      activeContact = true;
      break;
    default:
      activeHero = false;
  }
  
  


  const image = require("../../../assets/Icons/home-white.svg").default;
  const Icon = () => (
    <svg style={{ fill: "red", width: 40, position: "relative", height: 40 }}>
      <use xlinkHref={image} />
    </svg>
  );

 
  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  //     if (window.scrollY >= 0 && 742 > window.scrollY ) {
  //       setActiveHero(true);
  //     }else{
  //       setActiveHero(false);
  //     }

  //     if (window.scrollY >= 742 && 1111 > window.scrollY ) {
  //       setActiveProducts(true);
  //     }else{
  //       setActiveProducts(false);
  //     }
      
  //   });
  //   return () => {
  //     if (window.scrollY === 0) {
  //       window.removeEventListener("scroll",null);
  //     }
      
  //   };
  // }, []);

  const onHomeHandler = () => {
    activeHero = true;
    scroller.scrollTo("hero", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      spy: true,
      exact: "true",
      offset: -100,
    });
  };

  const onProductsHandler = () => {
    activeProducts = true;
    scroller.scrollTo("products", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      spy: true,
      exact: "true",
      offset: -70,
    });
  };

  const onAboutHandler = () => {
    activeAbout = true;
    scroller.scrollTo("about", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      spy: true,
      exact: "true",
      offset: 0,
    });
  };

  const onServicesHandler = () => {
    activeServices = true;
    scroller.scrollTo("services", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      spy: true,
      exact: "true",
      offset: 0,
    });
  };

  const onProjectsHandler = () => {
    activeProjects = true;
    scroller.scrollTo("projects", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      spy: true,
      exact: "true",
      offset: 0,
    });
  };

  const onContactHandler = () => {
    activeContact = true;
    scroller.scrollTo("contact", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      spy: true,
      exact: "true",
      offset: 0,
    });
  };

  // window.location.replace("/#hero")
  // window.location.href("/#hero")

  return (
    <div>
      <nav className="main-menu">
        <ul class="links" id="qmenu">
          <li class="list" data-menuanchor="home">
            <Wrap to="/hero">
              <i
                onClick={onHomeHandler}
                key="home"
                id="icon"
                class={`${"icon home"} ${activeHero && "active"}`}
              ></i>
              <span id="txt" class="txt">
                Home
              </span>
            </Wrap>
          </li>

          <li onClick={onProductsHandler} class="list" data-menuanchor="products">
            <Wrap>
              <i
                onClick={onProductsHandler}
                key="products"
                id="icon"
                class={`${"icon products"} ${activeProducts && "active"}`}
              ></i>
              <span data-tool-tip="Products" class="txt">
                Products
              </span>
            </Wrap>
          </li>

          <li onClick={onServicesHandler} class="list" data-menuanchor="services">
            <Wrap>
              <i
              onClick={onServicesHandler}
              key="services"
              id="icon" 
              class={`${"icon services"} ${activeServices && "active"}`}></i>
              <span class="txt">Services</span>
            </Wrap>
          </li>

          <li onClick={onAboutHandler} class="list" data-menuanchor="about-us">
            <Wrap>
              <i 
              onClick={onAboutHandler}
              key="about"
              id="icon"
              class={`${"icon about"} ${activeAbout && "active"}`}></i>
              <span class="txt">About</span>
            </Wrap>
          </li>

          <li onClick={onProjectsHandler} class="list" data-menuanchor="projects">
            <Wrap>
              <i onClick={onProjectsHandler}
                key="projects"
                id="icon"
                class={`${"icon projects"} ${activeProjects && "active"}`}></i>
              <span class="txt">Projects</span>
            </Wrap>
          </li>
          <li onClick={onContactHandler} class="list" data-menuanchor="contact">
            <Wrap>
              <i onClick={onContactHandler}
                key="contact"
                id="icon"
                class={`${"icon contact"} ${activeContact && "active"}`}></i>
              <span class="txt">Contact</span>
            </Wrap>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Menu;
