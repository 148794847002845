import React, { useEffect, useState } from "react";
import './landing.scss';
import { SliderEffect } from '../../utils/effect';
import { TweenLite } from 'gsap';

import { useSelector } from "react-redux";
// interface Props {
//   item: number;
//   animals: Animal[];
// }

const Landing = () => {

  const ImagePaths = [{
    path: require("../../../../assets/office.jpg").default,
  },
  {
    path: require("../../../../assets/products.jpg").default,
  },
  {
    path: require("../../../../assets/services.jpg").default,
  },
  {
    path: require("../../../../assets/about.jpg").default,
  },
  {
    path: require("../../../../assets/projects.jpg").default,
  },
  {
    path: require("../../../../assets/contact.jpg").default,
  },

]
  const [effects, setEffects] = useState();
  const currentElement = useSelector((state) => state.currentElement);
  const [item, setItem] = useState(0);
  const [getImg, setImg] = useState(require("../../../../assets/office.jpg").default);
  useEffect(()=>{
    switch (currentElement) {
      case "hero":
        setItem(0);
        
        break;
      case "products":
        setItem(1);
        
        break;
      case "services":
        setItem(2);
             
        break;
      case "about":
        setItem(3);
             
        break;
      case "projects":
        setItem(4);
        
        break;
      case "contact":
        setItem(5);
             
        break;
      default:
        setItem(0);
             
    
    }

  },[currentElement])

  useEffect(() => {
    const parent = document.querySelector('.landing');
    const images = document.querySelectorAll('.landing__image');

    if (parent && images) {
      const effects = SliderEffect({
        parent,
        images: Array.from(images)
      });
      setEffects(effects);
    }
  }, []);

  useEffect(() => {
    if (effects) {
      effects.material.uniforms.nextImage.value = effects.images[item];
      TweenLite.to(effects.material.uniforms.dispFactor, 1, {
        value: 1,
        ease: 'Expo.easeOut',
        onComplete: () => {
          effects.material.uniforms.currentImage.value = effects.images[item];
          effects.material.uniforms.dispFactor.value = 0.0;
        }
      });
    }
  }, [item, effects]);


  return (
    <div className="landing">
      { ImagePaths.map((img, index) => {
        return (
          <div style={{ width: '100%', height: '100vh' }} key={index} hidden={index !== item}>
            <img src={img.path} className="landing__image" alt="Animal"></img>
            
          </div>
        );
      })}
    </div>
  )
}

export default Landing;