import React from "react";
import "font-awesome/css/font-awesome.min.css";
// import classes from "../../../css/SlideShowTop.module.css";
import { NavCss, PrevCss, NextCss } from "./ArrowStyles.js";

const Arrow = (props) => {
  return (
    <NavCss onMouseOver={props.MouseOver} onMouseLeave={props.MouseLeave}>
      <PrevCss
        onClick={props.prevSlide}
        type="button"
        navColor={props.navColor}
      >
        <i className="fa fa-angle-left" aria-hidden="true"></i>
      </PrevCss>
      <NextCss
        onClick={props.nextSlide}
        type="button"
        navColor={props.navColor}
      >
        <i className="fa fa-angle-right" aria-hidden="true"></i>
      </NextCss>
    </NavCss>
  );
};

export default Arrow;
