import React, { Fragment } from "react";
import ReactDom from "react-dom";
import classes from "./ConsultancyForm.module.css";
import { CloseIcon, Icon } from './ConsultancyFormsStyles';

const portalElement = document.getElementById("overlays");

const ConsultancyForm = (props) => {
  return (
    <Fragment>
      {ReactDom.createPortal(<Backdrop onClick={props.onClick}/>, portalElement)}
      {ReactDom.createPortal(
        <ModalOverlay >{props.children}</ModalOverlay>,
        portalElement
      )}
    </Fragment>
  );
};

const Backdrop = (props) => {
  return <div className={classes.backdrop}>
       <Icon onClick={props.onClick}>
        <CloseIcon />
      </Icon>
  </div>;
};

const ModalOverlay = (props) => {
  return (
    <div className={classes.modal}>
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <h1>Leave us your details so that we can arrange for a consultation</h1>

          <form className={classes.form}>
            <input className={classes.nameField} type="text" placeholder="Name" />
            <input className={classes.emailField} type="text" placeholder="Email" />
            <textarea type="text" placeholder="Project description" />
            <button type="submit" id="login-button">
              Send
            </button>
          </form>
        </div>

        <ul className={classes['bg-bubbles']}>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </div>
  );
};

export default ConsultancyForm;
