import { createStore } from 'redux';
 
const activeClassReducer = (state = { currentElement: 'hero'}, action) => {
    if (action.type === 'change') {
        return {
            currentElement: action.currentElement,
        }
    }
    return state;
}

const store = createStore(activeClassReducer);

export default store;