import React, { useState, useRef, useEffect } from "react";
import styles from "./Hero.module.css";
import { Button } from "../UI/Buttons/ButtonStyle1/Button";
import { HeroObj } from "../Data";
import { ParaWrap, Anim, ConsultButton, ConsultButtonWrap } from "./HeroStyles";
import './HeroStyle.css';
import GooeyButton from '../UI/Buttons/GooeyButton/GooeyButton';
const Hero = (props) => {
  const heroEle = useRef();
  const loadEffects = props.loadEffects;
  const getHeight = getPosition(heroEle.current);

  console.log(getHeight, "hero");
  const { id, headLine, description} = HeroObj;

  const [executeAnime, setExecuteAnime] = useState(false);

  console.log(window.scrollY)
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setExecuteAnime(true);
      }
      else setExecuteAnime(false);
    });
    return () => {
      if (window.scrollY === 0) {
        window.removeEventListener("scroll",null);
      }
      
    };
  }, []);
  return (
    <>
      <div ref={heroEle} className={styles.home} id={id}>
        <div className={styles.heading1}>DELIVERING SOFTWARE</div>
        <h1>{headLine}</h1>
        <div className={styles.para1}>and we're good at it!</div>
        <ParaWrap loadEffects={loadEffects} className={styles.paraWrap}>
          <p>{description}</p>
        </ParaWrap>
        <ConsultButtonWrap onClick={props.consultancyButtonClicked}><GooeyButton className={styles.gooeyButton}/></ConsultButtonWrap>
        {/* <div id="box1"></div> */}
        <div class="col-12 col-lg-6 heroBanner-container-bg svg-animate ml-auto">
               <svg class='starSvg' version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="-300 0 804 804" preserveAspectRatio="xMaxYMin meet" xmlSpace="preserve">
                    
                    <g id="Layer_1" className={executeAnime ? 'Layer1_Execute' : ''}>
                        <g>
                            <line class="st0" x1="251.5" y1="11.7" x2="251.5" y2="252.2"></line>
                            <line class="st1" x1="250.7" y1="14" x2="249.1" y2="252.2"></line>
                            <line class="st2" x1="249.8" y1="16.4" x2="246.7" y2="252.2"></line>
                            <line class="st3" x1="248.9" y1="18.8" x2="244.4" y2="252.2"></line>
                            <line class="st4" x1="248.1" y1="21.2" x2="242" y2="252.2"></line>
                            <line class="st5" x1="247.2" y1="23.6" x2="239.6" y2="252.2"></line>
                            <line class="st6" x1="246.4" y1="26" x2="237.2" y2="252.2"></line>
                            <line class="st7" x1="245.5" y1="28.3" x2="234.8" y2="252.2"></line>
                            <line class="st8" x1="244.7" y1="30.7" x2="232.4" y2="252.2"></line>
                            <line class="st9" x1="243.8" y1="33.1" x2="230.1" y2="252.2"></line>
                            <line class="st10" x1="243" y1="35.5" x2="227.7" y2="252.2"></line>
                            <line class="st11" x1="242.1" y1="37.9" x2="225.3" y2="252.2"></line>
                            <line class="st12" x1="241.3" y1="40.3" x2="222.9" y2="252.2"></line>
                            <line class="st13" x1="240.4" y1="42.6" x2="220.5" y2="252.2"></line>
                            <line class="st14" x1="239.6" y1="45" x2="218.2" y2="252.2"></line>
                            <line class="st15" x1="238.7" y1="47.4" x2="215.8" y2="252.2"></line>
                            <line class="st16" x1="237.9" y1="49.8" x2="213.4" y2="252.2"></line>
                            <line class="st17" x1="237" y1="52.2" x2="211" y2="252.2"></line>
                            <line class="st18" x1="236.2" y1="54.5" x2="208.6" y2="252.2"></line>
                            <line class="st19" x1="235.3" y1="56.9" x2="206.2" y2="252.2"></line>
                            <line class="st20" x1="234.4" y1="59.3" x2="203.9" y2="252.2"></line>
                            <line class="st21" x1="233.6" y1="61.7" x2="201.5" y2="252.2"></line>
                            <line class="st22" x1="232.7" y1="64.1" x2="199.1" y2="252.2"></line>
                            <line class="st23" x1="231.9" y1="66.5" x2="196.7" y2="252.2"></line>
                            <line class="st24" x1="231" y1="68.8" x2="194.3" y2="252.2"></line>
                            <line class="st25" x1="230.2" y1="71.2" x2="192" y2="252.2"></line>
                            <line class="st26" x1="229.3" y1="73.6" x2="189.6" y2="252.2"></line>
                            <line class="st27" x1="228.5" y1="76" x2="187.2" y2="252.2"></line>
                            <line class="st28" x1="227.6" y1="78.4" x2="184.8" y2="252.2"></line>
                            <line class="st29" x1="226.8" y1="80.7" x2="182.4" y2="252.2"></line>
                            <line class="st30" x1="225.9" y1="83.1" x2="180" y2="252.2"></line>
                            <line class="st31" x1="225.1" y1="85.5" x2="177.7" y2="252.2"></line>
                            <line class="st32" x1="224.2" y1="87.9" x2="175.3" y2="252.2"></line>
                            <line class="st33" x1="223.4" y1="90.3" x2="172.9" y2="252.2"></line>
                            <line class="st34" x1="222.5" y1="92.7" x2="170.5" y2="252.2"></line>
                            <line class="st35" x1="221.7" y1="95" x2="168.1" y2="252.2"></line>
                            <line class="st36" x1="220.8" y1="97.4" x2="165.8" y2="252.2"></line>
                            <line class="st37" x1="219.9" y1="99.8" x2="163.4" y2="252.2"></line>
                            <line class="st38" x1="219.1" y1="102.2" x2="161" y2="252.2"></line>
                            <line class="st39" x1="218.2" y1="104.6" x2="158.6" y2="252.2"></line>
                            <line class="st40" x1="217.4" y1="106.9" x2="156.2" y2="252.2"></line>
                            <line class="st41" x1="216.5" y1="109.3" x2="153.8" y2="252.2"></line>
                            <line class="st42" x1="215.7" y1="111.7" x2="151.5" y2="252.2"></line>
                            <line class="st43" x1="214.8" y1="114.1" x2="149.1" y2="252.2"></line>
                            <line class="st44" x1="214" y1="116.5" x2="146.7" y2="252.2"></line>
                            <line class="st45" x1="213.1" y1="118.9" x2="144.3" y2="252.2"></line>
                            <line class="st46" x1="212.3" y1="121.2" x2="141.9" y2="252.2"></line>
                            <line class="st47" x1="211.4" y1="123.6" x2="139.6" y2="252.2"></line>
                            <line class="st48" x1="210.6" y1="126" x2="137.2" y2="252.2"></line>
                            <line class="st49" x1="209.7" y1="128.4" x2="134.8" y2="252.2"></line>
                            <line class="st50" x1="208.9" y1="130.8" x2="132.4" y2="252.2"></line>
                            <line class="st51" x1="208" y1="133.1" x2="130" y2="252.2"></line>
                            <line class="st52" x1="207.2" y1="135.5" x2="127.6" y2="252.2"></line>
                            <line class="st53" x1="206.3" y1="137.9" x2="125.3" y2="252.2"></line>
                            <line class="st54" x1="205.4" y1="140.3" x2="122.9" y2="252.2"></line>
                            <line class="st55" x1="204.6" y1="142.7" x2="120.5" y2="252.2"></line>
                            <line class="st56" x1="203.7" y1="145.1" x2="118.1" y2="252.2"></line>
                            <line class="st57" x1="202.9" y1="147.4" x2="115.7" y2="252.2"></line>
                            <line class="st58" x1="202" y1="149.8" x2="113.4" y2="252.2"></line>
                            <line class="st59" x1="201.2" y1="152.2" x2="111" y2="252.2"></line>
                            <line class="st60" x1="200.3" y1="154.6" x2="108.6" y2="252.2"></line>
                            <line class="st61" x1="199.5" y1="157" x2="106.2" y2="252.2"></line>
                            <line class="st62" x1="198.6" y1="159.3" x2="103.8" y2="252.2"></line>
                            <line class="st63" x1="197.8" y1="161.7" x2="101.4" y2="252.2"></line>
                            <line class="st64" x1="196.9" y1="164.1" x2="99.1" y2="252.2"></line>
                            <line class="st65" x1="196.1" y1="166.5" x2="96.7" y2="252.2"></line>
                            <line class="st66" x1="195.2" y1="168.9" x2="94.3" y2="252.2"></line>
                            <line class="st67" x1="194.4" y1="171.3" x2="91.9" y2="252.2"></line>
                            <line class="st68" x1="193.5" y1="173.6" x2="89.5" y2="252.2"></line>
                            <line class="st69" x1="192.7" y1="176" x2="87.1" y2="252.2"></line>
                            <line class="st70" x1="191.8" y1="178.4" x2="84.8" y2="252.2"></line>
                            <line class="st71" x1="190.9" y1="180.8" x2="82.4" y2="252.2"></line>
                            <line class="st72" x1="190.1" y1="183.2" x2="80" y2="252.2"></line>
                            <line class="st73" x1="189.2" y1="185.6" x2="77.6" y2="252.2"></line>
                            <line class="st74" x1="188.4" y1="187.9" x2="75.2" y2="252.2"></line>
                            <line class="st75" x1="187.5" y1="190.3" x2="72.9" y2="252.2"></line>
                            <line class="st76" x1="186.7" y1="192.7" x2="70.5" y2="252.2"></line>
                            <line class="st77" x1="185.8" y1="195.1" x2="68.1" y2="252.2"></line>
                            <line class="st78" x1="185" y1="197.5" x2="65.7" y2="252.2"></line>
                            <line class="st79" x1="184.1" y1="199.8" x2="63.3" y2="252.2"></line>
                            <line class="st80" x1="183.3" y1="202.2" x2="60.9" y2="252.2"></line>
                            <line class="st81" x1="182.4" y1="204.6" x2="58.6" y2="252.2"></line>
                            <line class="st82" x1="181.6" y1="207" x2="56.2" y2="252.2"></line>
                            <line class="st83" x1="180.7" y1="209.4" x2="53.8" y2="252.2"></line>
                            <line class="st84" x1="179.9" y1="211.8" x2="51.4" y2="252.2"></line>
                            <line class="st85" x1="179" y1="214.1" x2="49" y2="252.2"></line>
                            <line class="st86" x1="178.2" y1="216.5" x2="46.7" y2="252.2"></line>
                            <line class="st87" x1="177.3" y1="218.9" x2="44.3" y2="252.2"></line>
                            <line class="st88" x1="176.4" y1="221.3" x2="41.9" y2="252.2"></line>
                            <line class="st89" x1="175.6" y1="223.7" x2="39.5" y2="252.2"></line>
                            <line class="st90" x1="174.7" y1="226" x2="37.1" y2="252.2"></line>
                            <line class="st91" x1="173.9" y1="228.4" x2="34.7" y2="252.2"></line>
                            <line class="st92" x1="173" y1="230.8" x2="32.4" y2="252.2"></line>
                            <line class="st93" x1="172.2" y1="233.2" x2="30" y2="252.2"></line>
                            <line class="st94" x1="171.3" y1="235.6" x2="27.6" y2="252.2"></line>
                            <line class="st95" x1="170.5" y1="238" x2="25.2" y2="252.2"></line>
                            <line class="st96" x1="169.6" y1="240.3" x2="22.8" y2="252.2"></line>
                            <line class="st97" x1="168.8" y1="242.7" x2="20.5" y2="252.2"></line>
                            <line class="st98" x1="167.9" y1="245.1" x2="18.1" y2="252.2"></line>
                            <line class="st99" x1="167.1" y1="247.5" x2="15.7" y2="252.2"></line>
                            <line class="st100" x1="166.2" y1="249.9" x2="13.3" y2="252.2"></line>
                            <line class="st0" x1="251.5" y1="492.9" x2="251.5" y2="252.3"></line>
                            <line class="st1" x1="250.7" y1="490.5" x2="249.1" y2="252.3"></line>
                            <line class="st2" x1="249.8" y1="488.1" x2="246.7" y2="252.3"></line>
                            <line class="st3" x1="248.9" y1="485.7" x2="244.4" y2="252.3"></line>
                            <line class="st4" x1="248.1" y1="483.4" x2="242" y2="252.3"></line>
                            <line class="st5" x1="247.2" y1="481" x2="239.6" y2="252.3"></line>
                            <line class="st6" x1="246.4" y1="478.6" x2="237.2" y2="252.3"></line>
                            <line class="st7" x1="245.5" y1="476.2" x2="234.8" y2="252.3"></line>
                            <line class="st8" x1="244.7" y1="473.8" x2="232.4" y2="252.3"></line>
                            <line class="st9" x1="243.8" y1="471.4" x2="230.1" y2="252.3"></line>
                            <line class="st10" x1="243" y1="469.1" x2="227.7" y2="252.3"></line>
                            <line class="st11" x1="242.1" y1="466.7" x2="225.3" y2="252.3"></line>
                            <line class="st12" x1="241.3" y1="464.3" x2="222.9" y2="252.3"></line>
                            <line class="st13" x1="240.4" y1="461.9" x2="220.5" y2="252.3"></line>
                            <line class="st14" x1="239.6" y1="459.5" x2="218.2" y2="252.3"></line>
                            <line class="st15" x1="238.7" y1="457.1" x2="215.8" y2="252.3"></line>
                            <line class="st16" x1="237.9" y1="454.8" x2="213.4" y2="252.3"></line>
                            <line class="st17" x1="237" y1="452.4" x2="211" y2="252.3"></line>
                            <line class="st18" x1="236.2" y1="450" x2="208.6" y2="252.3"></line>
                            <line class="st19" x1="235.3" y1="447.6" x2="206.2" y2="252.3"></line>
                            <line class="st20" x1="234.4" y1="445.2" x2="203.9" y2="252.3"></line>
                            <line class="st21" x1="233.6" y1="442.9" x2="201.5" y2="252.3"></line>
                            <line class="st22" x1="232.7" y1="440.5" x2="199.1" y2="252.3"></line>
                            <line class="st23" x1="231.9" y1="438.1" x2="196.7" y2="252.3"></line>
                            <line class="st24" x1="231" y1="435.7" x2="194.3" y2="252.3"></line>
                            <line class="st25" x1="230.2" y1="433.3" x2="192" y2="252.3"></line>
                            <line class="st26" x1="229.3" y1="430.9" x2="189.6" y2="252.3"></line>
                            <line class="st27" x1="228.5" y1="428.6" x2="187.2" y2="252.3"></line>
                            <line class="st28" x1="227.6" y1="426.2" x2="184.8" y2="252.3"></line>
                            <line class="st29" x1="226.8" y1="423.8" x2="182.4" y2="252.3"></line>
                            <line class="st30" x1="225.9" y1="421.4" x2="180" y2="252.3"></line>
                            <line class="st31" x1="225.1" y1="419" x2="177.7" y2="252.3"></line>
                            <line class="st32" x1="224.2" y1="416.7" x2="175.3" y2="252.3"></line>
                            <line class="st33" x1="223.4" y1="414.3" x2="172.9" y2="252.3"></line>
                            <line class="st34" x1="222.5" y1="411.9" x2="170.5" y2="252.3"></line>
                            <line class="st35" x1="221.7" y1="409.5" x2="168.1" y2="252.3"></line>
                            <line class="st36" x1="220.8" y1="407.1" x2="165.8" y2="252.3"></line>
                            <line class="st37" x1="219.9" y1="404.7" x2="163.4" y2="252.3"></line>
                            <line class="st38" x1="219.1" y1="402.4" x2="161" y2="252.3"></line>
                            <line class="st39" x1="218.2" y1="400" x2="158.6" y2="252.3"></line>
                            <line class="st40" x1="217.4" y1="397.6" x2="156.2" y2="252.3"></line>
                            <line class="st41" x1="216.5" y1="395.2" x2="153.8" y2="252.3"></line>
                            <line class="st42" x1="215.7" y1="392.8" x2="151.5" y2="252.3"></line>
                            <line class="st43" x1="214.8" y1="390.5" x2="149.1" y2="252.3"></line>
                            <line class="st44" x1="214" y1="388.1" x2="146.7" y2="252.3"></line>
                            <line class="st45" x1="213.1" y1="385.7" x2="144.3" y2="252.3"></line>
                            <line class="st46" x1="212.3" y1="383.3" x2="141.9" y2="252.3"></line>
                            <line class="st47" x1="211.4" y1="380.9" x2="139.6" y2="252.3"></line>
                            <line class="st48" x1="210.6" y1="378.5" x2="137.2" y2="252.3"></line>
                            <line class="st49" x1="209.7" y1="376.2" x2="134.8" y2="252.3"></line>
                            <line class="st50" x1="208.9" y1="373.8" x2="132.4" y2="252.3"></line>
                            <line class="st51" x1="208" y1="371.4" x2="130" y2="252.3"></line>
                            <line class="st52" x1="207.2" y1="369" x2="127.6" y2="252.3"></line>
                            <line class="st53" x1="206.3" y1="366.6" x2="125.3" y2="252.3"></line>
                            <line class="st54" x1="205.4" y1="364.3" x2="122.9" y2="252.3"></line>
                            <line class="st55" x1="204.6" y1="361.9" x2="120.5" y2="252.3"></line>
                            <line class="st56" x1="203.7" y1="359.5" x2="118.1" y2="252.3"></line>
                            <line class="st57" x1="202.9" y1="357.1" x2="115.7" y2="252.3"></line>
                            <line class="st58" x1="202" y1="354.7" x2="113.4" y2="252.3"></line>
                            <line class="st59" x1="201.2" y1="352.3" x2="111" y2="252.3"></line>
                            <line class="st60" x1="200.3" y1="350" x2="108.6" y2="252.3"></line>
                            <line class="st61" x1="199.5" y1="347.6" x2="106.2" y2="252.3"></line>
                            <line class="st62" x1="198.6" y1="345.2" x2="103.8" y2="252.3"></line>
                            <line class="st63" x1="197.8" y1="342.8" x2="101.4" y2="252.3"></line>
                            <line class="st64" x1="196.9" y1="340.4" x2="99.1" y2="252.3"></line>
                            <line class="st65" x1="196.1" y1="338.1" x2="96.7" y2="252.3"></line>
                            <line class="st66" x1="195.2" y1="335.7" x2="94.3" y2="252.3"></line>
                            <line class="st67" x1="194.4" y1="333.3" x2="91.9" y2="252.3"></line>
                            <line class="st68" x1="193.5" y1="330.9" x2="89.5" y2="252.3"></line>
                            <line class="st69" x1="192.7" y1="328.5" x2="87.1" y2="252.3"></line>
                            <line class="st70" x1="191.8" y1="326.1" x2="84.8" y2="252.3"></line>
                            <line class="st71" x1="190.9" y1="323.8" x2="82.4" y2="252.3"></line>
                            <line class="st72" x1="190.1" y1="321.4" x2="80" y2="252.3"></line>
                            <line class="st73" x1="189.2" y1="319" x2="77.6" y2="252.3"></line>
                            <line class="st74" x1="188.4" y1="316.6" x2="75.2" y2="252.3"></line>
                            <line class="st75" x1="187.5" y1="314.2" x2="72.9" y2="252.3"></line>
                            <line class="st76" x1="186.7" y1="311.8" x2="70.5" y2="252.3"></line>
                            <line class="st77" x1="185.8" y1="309.5" x2="68.1" y2="252.3"></line>
                            <line class="st78" x1="185" y1="307.1" x2="65.7" y2="252.3"></line>
                            <line class="st79" x1="184.1" y1="304.7" x2="63.3" y2="252.3"></line>
                            <line class="st80" x1="183.3" y1="302.3" x2="60.9" y2="252.3"></line>
                            <line class="st81" x1="182.4" y1="299.9" x2="58.6" y2="252.3"></line>
                            <line class="st82" x1="181.6" y1="297.6" x2="56.2" y2="252.3"></line>
                            <line class="st83" x1="180.7" y1="295.2" x2="53.8" y2="252.3"></line>
                            <line class="st84" x1="179.9" y1="292.8" x2="51.4" y2="252.3"></line>
                            <line class="st85" x1="179" y1="290.4" x2="49" y2="252.3"></line>
                            <line class="st86" x1="178.2" y1="288" x2="46.7" y2="252.3"></line>
                            <line class="st87" x1="177.3" y1="285.6" x2="44.3" y2="252.3"></line>
                            <line class="st88" x1="176.4" y1="283.3" x2="41.9" y2="252.3"></line>
                            <line class="st89" x1="175.6" y1="280.9" x2="39.5" y2="252.3"></line>
                            <line class="st90" x1="174.7" y1="278.5" x2="37.1" y2="252.3"></line>
                            <line class="st91" x1="173.9" y1="276.1" x2="34.7" y2="252.3"></line>
                            <line class="st92" x1="173" y1="273.7" x2="32.4" y2="252.3"></line>
                            <line class="st93" x1="172.2" y1="271.4" x2="30" y2="252.3"></line>
                            <line class="st94" x1="171.3" y1="269" x2="27.6" y2="252.3"></line>
                            <line class="st95" x1="170.5" y1="266.6" x2="25.2" y2="252.3"></line>
                            <line class="st96" x1="169.6" y1="264.2" x2="22.8" y2="252.3"></line>
                            <line class="st97" x1="168.8" y1="261.8" x2="20.5" y2="252.3"></line>
                            <line class="st98" x1="167.9" y1="259.4" x2="18.1" y2="252.3"></line>
                            <line class="st99" x1="167.1" y1="257.1" x2="15.7" y2="252.3"></line>
                            <line class="st100" x1="166.2" y1="254.7" x2="13.3" y2="252.3"></line>
                            <line class="st101" x1="252.4" y1="14" x2="254" y2="252.2"></line>
                            <line class="st102" x1="253.3" y1="16.4" x2="256.3" y2="252.2"></line>
                            <line class="st103" x1="254.1" y1="18.8" x2="258.7" y2="252.2"></line>
                            <line class="st104" x1="255" y1="21.2" x2="261.1" y2="252.2"></line>
                            <line class="st105" x1="255.9" y1="23.6" x2="263.5" y2="252.2"></line>
                            <line class="st106" x1="256.7" y1="26" x2="265.9" y2="252.2"></line>
                            <line class="st107" x1="257.6" y1="28.3" x2="268.3" y2="252.2"></line>
                            <line class="st108" x1="258.4" y1="30.7" x2="270.6" y2="252.2"></line>
                            <line class="st109" x1="259.3" y1="33.1" x2="273" y2="252.2"></line>
                            <line class="st110" x1="260.1" y1="35.5" x2="275.4" y2="252.2"></line>
                            <line class="st111" x1="261" y1="37.9" x2="277.8" y2="252.2"></line>
                            <line class="st112" x1="261.8" y1="40.3" x2="280.2" y2="252.2"></line>
                            <line class="st113" x1="262.7" y1="42.6" x2="282.6" y2="252.2"></line>
                            <line class="st114" x1="263.5" y1="45" x2="284.9" y2="252.2"></line>
                            <line class="st115" x1="264.4" y1="47.4" x2="287.3" y2="252.2"></line>
                            <line class="st116" x1="265.2" y1="49.8" x2="289.7" y2="252.2"></line>
                            <line class="st117" x1="266.1" y1="52.2" x2="292.1" y2="252.2"></line>
                            <line class="st118" x1="266.9" y1="54.5" x2="294.5" y2="252.2"></line>
                            <line class="st119" x1="267.8" y1="56.9" x2="296.8" y2="252.2"></line>
                            <line class="st120" x1="268.6" y1="59.3" x2="299.2" y2="252.2"></line>
                            <line class="st121" x1="269.5" y1="61.7" x2="301.6" y2="252.2"></line>
                            <line class="st122" x1="270.3" y1="64.1" x2="304" y2="252.2"></line>
                            <line class="st123" x1="271.2" y1="66.5" x2="306.4" y2="252.2"></line>
                            <line class="st124" x1="272.1" y1="68.8" x2="308.8" y2="252.2"></line>
                            <line class="st125" x1="272.9" y1="71.2" x2="311.1" y2="252.2"></line>
                            <line class="st126" x1="273.8" y1="73.6" x2="313.5" y2="252.2"></line>
                            <line class="st127" x1="274.6" y1="76" x2="315.9" y2="252.2"></line>
                            <line class="st128" x1="275.5" y1="78.4" x2="318.3" y2="252.2"></line>
                            <line class="st129" x1="276.3" y1="80.7" x2="320.7" y2="252.2"></line>
                            <line class="st130" x1="277.2" y1="83.1" x2="323" y2="252.2"></line>
                            <line class="st131" x1="278" y1="85.5" x2="325.4" y2="252.2"></line>
                            <line class="st132" x1="278.9" y1="87.9" x2="327.8" y2="252.2"></line>
                            <line class="st133" x1="279.7" y1="90.3" x2="330.2" y2="252.2"></line>
                            <line class="st134" x1="280.6" y1="92.7" x2="332.6" y2="252.2"></line>
                            <line class="st135" x1="281.4" y1="95" x2="335" y2="252.2"></line>
                            <line class="st136" x1="282.3" y1="97.4" x2="337.3" y2="252.2"></line>
                            <line class="st137" x1="283.1" y1="99.8" x2="339.7" y2="252.2"></line>
                            <line class="st138" x1="284" y1="102.2" x2="342.1" y2="252.2"></line>
                            <line class="st139" x1="284.8" y1="104.6" x2="344.5" y2="252.2"></line>
                            <line class="st140" x1="285.7" y1="106.9" x2="346.9" y2="252.2"></line>
                            <line class="st141" x1="286.6" y1="109.3" x2="349.2" y2="252.2"></line>
                            <line class="st142" x1="287.4" y1="111.7" x2="351.6" y2="252.2"></line>
                            <line class="st143" x1="288.3" y1="114.1" x2="354" y2="252.2"></line>
                            <line class="st144" x1="289.1" y1="116.5" x2="356.4" y2="252.2"></line>
                            <line class="st145" x1="290" y1="118.9" x2="358.8" y2="252.2"></line>
                            <line class="st146" x1="290.8" y1="121.2" x2="361.2" y2="252.2"></line>
                            <line class="st147" x1="291.7" y1="123.6" x2="363.5" y2="252.2"></line>
                            <line class="st148" x1="292.5" y1="126" x2="365.9" y2="252.2"></line>
                            <line class="st149" x1="293.4" y1="128.4" x2="368.3" y2="252.2"></line>
                            <line class="st150" x1="294.2" y1="130.8" x2="370.7" y2="252.2"></line>
                            <line class="st151" x1="295.1" y1="133.1" x2="373.1" y2="252.2"></line>
                            <line class="st152" x1="295.9" y1="135.5" x2="375.4" y2="252.2"></line>
                            <line class="st153" x1="296.8" y1="137.9" x2="377.8" y2="252.2"></line>
                            <line class="st154" x1="297.6" y1="140.3" x2="380.2" y2="252.2"></line>
                            <line class="st155" x1="298.5" y1="142.7" x2="382.6" y2="252.2"></line>
                            <line class="st156" x1="299.3" y1="145.1" x2="385" y2="252.2"></line>
                            <line class="st157" x1="300.2" y1="147.4" x2="387.4" y2="252.2"></line>
                            <line class="st158" x1="301.1" y1="149.8" x2="389.7" y2="252.2"></line>
                            <line class="st159" x1="301.9" y1="152.2" x2="392.1" y2="252.2"></line>
                            <line class="st160" x1="302.8" y1="154.6" x2="394.5" y2="252.2"></line>
                            <line class="st161" x1="303.6" y1="157" x2="396.9" y2="252.2"></line>
                            <line class="st162" x1="304.5" y1="159.3" x2="399.3" y2="252.2"></line>
                            <line class="st163" x1="305.3" y1="161.7" x2="401.6" y2="252.2"></line>
                            <line class="st164" x1="306.2" y1="164.1" x2="404" y2="252.2"></line>
                            <line class="st165" x1="307" y1="166.5" x2="406.4" y2="252.2"></line>
                            <line class="st166" x1="307.9" y1="168.9" x2="408.8" y2="252.2"></line>
                            <line class="st167" x1="308.7" y1="171.3" x2="411.2" y2="252.2"></line>
                            <line class="st168" x1="309.6" y1="173.6" x2="413.6" y2="252.2"></line>
                            <line class="st169" x1="310.4" y1="176" x2="415.9" y2="252.2"></line>
                            <line class="st170" x1="311.3" y1="178.4" x2="418.3" y2="252.2"></line>
                            <line class="st171" x1="312.1" y1="180.8" x2="420.7" y2="252.2"></line>
                            <line class="st172" x1="313" y1="183.2" x2="423.1" y2="252.2"></line>
                            <line class="st173" x1="313.8" y1="185.6" x2="425.5" y2="252.2"></line>
                            <line class="st174" x1="314.7" y1="187.9" x2="427.9" y2="252.2"></line>
                            <line class="st175" x1="315.6" y1="190.3" x2="430.2" y2="252.2"></line>
                            <line class="st176" x1="316.4" y1="192.7" x2="432.6" y2="252.2"></line>
                            <line class="st177" x1="317.3" y1="195.1" x2="435" y2="252.2"></line>
                            <line class="st178" x1="318.1" y1="197.5" x2="437.4" y2="252.2"></line>
                            <line class="st179" x1="319" y1="199.8" x2="439.8" y2="252.2"></line>
                            <line class="st180" x1="319.8" y1="202.2" x2="442.1" y2="252.2"></line>
                            <line class="st181" x1="320.7" y1="204.6" x2="444.5" y2="252.2"></line>
                            <line class="st182" x1="321.5" y1="207" x2="446.9" y2="252.2"></line>
                            <line class="st183" x1="322.4" y1="209.4" x2="449.3" y2="252.2"></line>
                            <line class="st184" x1="323.2" y1="211.8" x2="451.7" y2="252.2"></line>
                            <line class="st185" x1="324.1" y1="214.1" x2="454.1" y2="252.2"></line>
                            <line class="st186" x1="324.9" y1="216.5" x2="456.4" y2="252.2"></line>
                            <line class="st187" x1="325.8" y1="218.9" x2="458.8" y2="252.2"></line>
                            <line class="st188" x1="326.6" y1="221.3" x2="461.2" y2="252.2"></line>
                            <line class="st189" x1="327.5" y1="223.7" x2="463.6" y2="252.2"></line>
                            <line class="st190" x1="328.3" y1="226" x2="466" y2="252.2"></line>
                            <line class="st191" x1="329.2" y1="228.4" x2="468.3" y2="252.2"></line>
                            <line class="st192" x1="330.1" y1="230.8" x2="470.7" y2="252.2"></line>
                            <line class="st193" x1="330.9" y1="233.2" x2="473.1" y2="252.2"></line>
                            <line class="st194" x1="331.8" y1="235.6" x2="475.5" y2="252.2"></line>
                            <line class="st195" x1="332.6" y1="238" x2="477.9" y2="252.2"></line>
                            <line class="st196" x1="333.5" y1="240.3" x2="480.3" y2="252.2"></line>
                            <line class="st197" x1="334.3" y1="242.7" x2="482.6" y2="252.2"></line>
                            <line class="st198" x1="335.2" y1="245.1" x2="485" y2="252.2"></line>
                            <line class="st199" x1="336" y1="247.5" x2="487.4" y2="252.2"></line>
                            <line class="st200" x1="336.9" y1="249.9" x2="489.8" y2="252.2"></line>
                            <line class="st101" x1="252.4" y1="490.4" x2="254" y2="252.2"></line>
                            <line class="st102" x1="253.3" y1="488.1" x2="256.3" y2="252.2"></line>
                            <line class="st103" x1="254.1" y1="485.7" x2="258.7" y2="252.2"></line>
                            <line class="st104" x1="255" y1="483.3" x2="261.1" y2="252.2"></line>
                            <line class="st105" x1="255.9" y1="480.9" x2="263.5" y2="252.2"></line>
                            <line class="st106" x1="256.7" y1="478.5" x2="265.9" y2="252.2"></line>
                            <line class="st107" x1="257.6" y1="476.2" x2="268.3" y2="252.2"></line>
                            <line class="st108" x1="258.4" y1="473.8" x2="270.6" y2="252.2"></line>
                            <line class="st109" x1="259.3" y1="471.4" x2="273" y2="252.2"></line>
                            <line class="st110" x1="260.1" y1="469" x2="275.4" y2="252.2"></line>
                            <line class="st111" x1="261" y1="466.6" x2="277.8" y2="252.2"></line>
                            <line class="st112" x1="261.8" y1="464.2" x2="280.2" y2="252.2"></line>
                            <line class="st113" x1="262.7" y1="461.9" x2="282.6" y2="252.2"></line>
                            <line class="st114" x1="263.5" y1="459.5" x2="284.9" y2="252.2"></line>
                            <line class="st115" x1="264.4" y1="457.1" x2="287.3" y2="252.2"></line>
                            <line class="st116" x1="265.2" y1="454.7" x2="289.7" y2="252.2"></line>
                            <line class="st117" x1="266.1" y1="452.3" x2="292.1" y2="252.2"></line>
                            <line class="st118" x1="266.9" y1="449.9" x2="294.5" y2="252.2"></line>
                            <line class="st119" x1="267.8" y1="447.6" x2="296.8" y2="252.2"></line>
                            <line class="st120" x1="268.6" y1="445.2" x2="299.2" y2="252.2"></line>
                            <line class="st121" x1="269.5" y1="442.8" x2="301.6" y2="252.2"></line>
                            <line class="st122" x1="270.3" y1="440.4" x2="304" y2="252.2"></line>
                            <line class="st123" x1="271.2" y1="438" x2="306.4" y2="252.2"></line>
                            <line class="st124" x1="272.1" y1="435.7" x2="308.8" y2="252.2"></line>
                            <line class="st125" x1="272.9" y1="433.3" x2="311.1" y2="252.2"></line>
                            <line class="st126" x1="273.8" y1="430.9" x2="313.5" y2="252.2"></line>
                            <line class="st127" x1="274.6" y1="428.5" x2="315.9" y2="252.2"></line>
                            <line class="st128" x1="275.5" y1="426.1" x2="318.3" y2="252.2"></line>
                            <line class="st129" x1="276.3" y1="423.7" x2="320.7" y2="252.2"></line>
                            <line class="st130" x1="277.2" y1="421.4" x2="323" y2="252.2"></line>
                            <line class="st131" x1="278" y1="419" x2="325.4" y2="252.2"></line>
                            <line class="st132" x1="278.9" y1="416.6" x2="327.8" y2="252.2"></line>
                            <line class="st133" x1="279.7" y1="414.2" x2="330.2" y2="252.2"></line>
                            <line class="st134" x1="280.6" y1="411.8" x2="332.6" y2="252.2"></line>
                            <line class="st135" x1="281.4" y1="409.5" x2="335" y2="252.2"></line>
                            <line class="st136" x1="282.3" y1="407.1" x2="337.3" y2="252.2"></line>
                            <line class="st137" x1="283.1" y1="404.7" x2="339.7" y2="252.2"></line>
                            <line class="st138" x1="284" y1="402.3" x2="342.1" y2="252.2"></line>
                            <line class="st139" x1="284.8" y1="399.9" x2="344.5" y2="252.2"></line>
                            <line class="st140" x1="285.7" y1="397.5" x2="346.9" y2="252.2"></line>
                            <line class="st141" x1="286.6" y1="395.2" x2="349.2" y2="252.2"></line>
                            <line class="st142" x1="287.4" y1="392.8" x2="351.6" y2="252.2"></line>
                            <line class="st143" x1="288.3" y1="390.4" x2="354" y2="252.2"></line>
                            <line class="st144" x1="289.1" y1="388" x2="356.4" y2="252.2"></line>
                            <line class="st145" x1="290" y1="385.6" x2="358.8" y2="252.2"></line>
                            <line class="st146" x1="290.8" y1="383.3" x2="361.2" y2="252.2"></line>
                            <line class="st147" x1="291.7" y1="380.9" x2="363.5" y2="252.2"></line>
                            <line class="st148" x1="292.5" y1="378.5" x2="365.9" y2="252.2"></line>
                            <line class="st149" x1="293.4" y1="376.1" x2="368.3" y2="252.2"></line>
                            <line class="st150" x1="294.2" y1="373.7" x2="370.7" y2="252.2"></line>
                            <line class="st151" x1="295.1" y1="371.3" x2="373.1" y2="252.2"></line>
                            <line class="st152" x1="295.9" y1="369" x2="375.4" y2="252.2"></line>
                            <line class="st153" x1="296.8" y1="366.6" x2="377.8" y2="252.2"></line>
                            <line class="st154" x1="297.6" y1="364.2" x2="380.2" y2="252.2"></line>
                            <line class="st155" x1="298.5" y1="361.8" x2="382.6" y2="252.2"></line>
                            <line class="st156" x1="299.3" y1="359.4" x2="385" y2="252.2"></line>
                            <line class="st157" x1="300.2" y1="357.1" x2="387.4" y2="252.2"></line>
                            <line class="st158" x1="301.1" y1="354.7" x2="389.7" y2="252.2"></line>
                            <line class="st159" x1="301.9" y1="352.3" x2="392.1" y2="252.2"></line>
                            <line class="st160" x1="302.8" y1="349.9" x2="394.5" y2="252.2"></line>
                            <line class="st161" x1="303.6" y1="347.5" x2="396.9" y2="252.2"></line>
                            <line class="st162" x1="304.5" y1="345.1" x2="399.3" y2="252.2"></line>
                            <line class="st163" x1="305.3" y1="342.8" x2="401.6" y2="252.2"></line>
                            <line class="st164" x1="306.2" y1="340.4" x2="404" y2="252.2"></line>
                            <line class="st165" x1="307" y1="338" x2="406.4" y2="252.2"></line>
                            <line class="st166" x1="307.9" y1="335.6" x2="408.8" y2="252.2"></line>
                            <line class="st167" x1="308.7" y1="333.2" x2="411.2" y2="252.2"></line>
                            <line class="st168" x1="309.6" y1="330.9" x2="413.6" y2="252.2"></line>
                            <line class="st169" x1="310.4" y1="328.5" x2="415.9" y2="252.2"></line>
                            <line class="st170" x1="311.3" y1="326.1" x2="418.3" y2="252.2"></line>
                            <line class="st171" x1="312.1" y1="323.7" x2="420.7" y2="252.2"></line>
                            <line class="st172" x1="313" y1="321.3" x2="423.1" y2="252.2"></line>
                            <line class="st173" x1="313.8" y1="318.9" x2="425.5" y2="252.2"></line>
                            <line class="st174" x1="314.7" y1="316.6" x2="427.9" y2="252.2"></line>
                            <line class="st175" x1="315.6" y1="314.2" x2="430.2" y2="252.2"></line>
                            <line class="st176" x1="316.4" y1="311.8" x2="432.6" y2="252.2"></line>
                            <line class="st177" x1="317.3" y1="309.4" x2="435" y2="252.2"></line>
                            <line class="st178" x1="318.1" y1="307" x2="437.4" y2="252.2"></line>
                            <line class="st179" x1="319" y1="304.6" x2="439.8" y2="252.2"></line>
                            <line class="st180" x1="319.8" y1="302.3" x2="442.1" y2="252.2"></line>
                            <line class="st181" x1="320.7" y1="299.9" x2="444.5" y2="252.2"></line>
                            <line class="st182" x1="321.5" y1="297.5" x2="446.9" y2="252.2"></line>
                            <line class="st183" x1="322.4" y1="295.1" x2="449.3" y2="252.2"></line>
                            <line class="st184" x1="323.2" y1="292.7" x2="451.7" y2="252.2"></line>
                            <line class="st185" x1="324.1" y1="290.4" x2="454.1" y2="252.2"></line>
                            <line class="st186" x1="324.9" y1="288" x2="456.4" y2="252.2"></line>
                            <line class="st187" x1="325.8" y1="285.6" x2="458.8" y2="252.2"></line>
                            <line class="st188" x1="326.6" y1="283.2" x2="461.2" y2="252.2"></line>
                            <line class="st189" x1="327.5" y1="280.8" x2="463.6" y2="252.2"></line>
                            <line class="st190" x1="328.3" y1="278.4" x2="466" y2="252.2"></line>
                            <line class="st191" x1="329.2" y1="276.1" x2="468.3" y2="252.2"></line>
                            <line class="st192" x1="330.1" y1="273.7" x2="470.7" y2="252.2"></line>
                            <line class="st193" x1="330.9" y1="271.3" x2="473.1" y2="252.2"></line>
                            <line class="st194" x1="331.8" y1="268.9" x2="475.5" y2="252.2"></line>
                            <line class="st195" x1="332.6" y1="266.5" x2="477.9" y2="252.2"></line>
                            <line class="st196" x1="333.5" y1="264.2" x2="480.3" y2="252.2"></line>
                            <line class="st197" x1="334.3" y1="261.8" x2="482.6" y2="252.2"></line>
                            <line class="st198" x1="335.2" y1="259.4" x2="485" y2="252.2"></line>
                            <line class="st199" x1="336" y1="257" x2="487.4" y2="252.2"></line>
                            <line class="st200" x1="336.9" y1="254.6" x2="489.8" y2="252.2"></line>
                            <line class="st201" x1="10.9" y1="252.2" x2="165.4" y2="252.2"></line>
                            <line class="st202" x1="492.2" y1="252.2" x2="337.7" y2="252.2"></line>
                        </g>
                    </g>
                    <g id="Layer_3" className={executeAnime ? 'Layer2_Execute' : ''}>
                        <g>
                            <line class="st203" x1="251.6" y1="110.8" x2="251.6" y2="252.2"></line>
                            <line class="st204" x1="251.1" y1="112.2" x2="250.2" y2="252.2"></line>
                            <line class="st205" x1="250.6" y1="113.6" x2="248.8" y2="252.2"></line>
                            <line class="st206" x1="250.1" y1="115" x2="247.4" y2="252.2"></line>
                            <line class="st207" x1="249.6" y1="116.4" x2="246" y2="252.2"></line>
                            <line class="st208" x1="249.1" y1="117.8" x2="244.6" y2="252.2"></line>
                            <line class="st209" x1="248.6" y1="119.2" x2="243.2" y2="252.2"></line>
                            <line class="st210" x1="248.1" y1="120.6" x2="241.8" y2="252.2"></line>
                            <line class="st211" x1="247.6" y1="122" x2="240.4" y2="252.2"></line>
                            <line class="st211" x1="247.1" y1="123.4" x2="239" y2="252.2"></line>
                            <line class="st212" x1="246.6" y1="124.8" x2="237.6" y2="252.2"></line>
                            <line class="st213" x1="246.1" y1="126.2" x2="236.2" y2="252.2"></line>
                            <line class="st214" x1="245.6" y1="127.6" x2="234.8" y2="252.2"></line>
                            <line class="st215" x1="245.1" y1="129" x2="233.4" y2="252.2"></line>
                            <line class="st216" x1="244.6" y1="130.4" x2="232" y2="252.2"></line>
                            <line class="st216" x1="244.1" y1="131.8" x2="230.6" y2="252.2"></line>
                            <line class="st217" x1="243.6" y1="133.2" x2="229.2" y2="252.2"></line>
                            <line class="st218" x1="243.1" y1="134.6" x2="227.8" y2="252.2"></line>
                            <line class="st219" x1="242.6" y1="136" x2="226.4" y2="252.2"></line>
                            <line class="st220" x1="242.1" y1="137.4" x2="225" y2="252.2"></line>
                            <line class="st221" x1="241.6" y1="138.8" x2="223.6" y2="252.2"></line>
                            <line class="st221" x1="241.1" y1="140.2" x2="222.2" y2="252.2"></line>
                            <line class="st222" x1="240.6" y1="141.6" x2="220.8" y2="252.2"></line>
                            <line class="st223" x1="240.1" y1="143" x2="219.4" y2="252.2"></line>
                            <line class="st224" x1="239.6" y1="144.4" x2="218" y2="252.2"></line>
                            <line class="st225" x1="239.1" y1="145.8" x2="216.6" y2="252.2"></line>
                            <line class="st226" x1="238.6" y1="147.2" x2="215.2" y2="252.2"></line>
                            <line class="st226" x1="238" y1="148.6" x2="213.8" y2="252.2"></line>
                            <line class="st227" x1="237.5" y1="150" x2="212.4" y2="252.2"></line>
                            <line class="st228" x1="237" y1="151.4" x2="211" y2="252.2"></line>
                            <line class="st229" x1="236.5" y1="152.8" x2="209.6" y2="252.2"></line>
                            <line class="st230" x1="236" y1="154.2" x2="208.2" y2="252.2"></line>
                            <line class="st231" x1="235.5" y1="155.6" x2="206.8" y2="252.2"></line>
                            <line class="st232" x1="235" y1="157" x2="205.4" y2="252.2"></line>
                            <line class="st233" x1="234.5" y1="158.4" x2="204" y2="252.2"></line>
                            <line class="st234" x1="234" y1="159.8" x2="202.6" y2="252.2"></line>
                            <line class="st235" x1="233.5" y1="161.2" x2="201.2" y2="252.2"></line>
                            <line class="st236" x1="233" y1="162.6" x2="199.8" y2="252.2"></line>
                            <line class="st237" x1="232.5" y1="164" x2="198.4" y2="252.2"></line>
                            <line class="st237" x1="232" y1="165.4" x2="197" y2="252.2"></line>
                            <line class="st238" x1="231.5" y1="166.8" x2="195.6" y2="252.2"></line>
                            <line class="st239" x1="231" y1="168.2" x2="194.2" y2="252.2"></line>
                            <line class="st240" x1="230.5" y1="169.6" x2="192.8" y2="252.2"></line>
                            <line class="st241" x1="230" y1="171" x2="191.4" y2="252.2"></line>
                            <line class="st242" x1="229.5" y1="172.4" x2="190" y2="252.2"></line>
                            <line class="st242" x1="229" y1="173.8" x2="188.6" y2="252.2"></line>
                            <line class="st243" x1="228.5" y1="175.2" x2="187.2" y2="252.2"></line>
                            <line class="st244" x1="228" y1="176.6" x2="185.8" y2="252.2"></line>
                            <line class="st245" x1="227.5" y1="178" x2="184.4" y2="252.2"></line>
                            <line class="st246" x1="227" y1="179.4" x2="183" y2="252.2"></line>
                            <line class="st247" x1="226.5" y1="180.8" x2="181.6" y2="252.2"></line>
                            <line class="st248" x1="226" y1="182.2" x2="180.2" y2="252.2"></line>
                            <line class="st249" x1="225.5" y1="183.6" x2="178.8" y2="252.2"></line>
                            <line class="st250" x1="225" y1="185" x2="177.4" y2="252.2"></line>
                            <line class="st251" x1="224.5" y1="186.4" x2="176" y2="252.2"></line>
                            <line class="st252" x1="224" y1="187.8" x2="174.6" y2="252.2"></line>
                            <line class="st253" x1="223.5" y1="189.2" x2="173.2" y2="252.2"></line>
                            <line class="st253" x1="223" y1="190.6" x2="171.8" y2="252.2"></line>
                            <line class="st254" x1="222.5" y1="192" x2="170.4" y2="252.2"></line>
                            <line class="st255" x1="222" y1="193.4" x2="169" y2="252.2"></line>
                            <line class="st256" x1="221.5" y1="194.8" x2="167.6" y2="252.2"></line>
                            <line class="st257" x1="221" y1="196.2" x2="166.2" y2="252.2"></line>
                            <line class="st258" x1="220.5" y1="197.6" x2="164.8" y2="252.2"></line>
                            <line class="st258" x1="220" y1="199" x2="163.4" y2="252.2"></line>
                            <line class="st259" x1="219.5" y1="200.4" x2="162" y2="252.2"></line>
                            <line class="st260" x1="219" y1="201.8" x2="160.6" y2="252.2"></line>
                            <line class="st261" x1="218.5" y1="203.2" x2="159.2" y2="252.2"></line>
                            <line class="st262" x1="218" y1="204.6" x2="157.8" y2="252.2"></line>
                            <line class="st263" x1="217.5" y1="206" x2="156.4" y2="252.2"></line>
                            <line class="st264" x1="217" y1="207.4" x2="155" y2="252.2"></line>
                            <line class="st265" x1="216.5" y1="208.8" x2="153.6" y2="252.2"></line>
                            <line class="st266" x1="216" y1="210.2" x2="152.2" y2="252.2"></line>
                            <line class="st267" x1="215.5" y1="211.6" x2="150.8" y2="252.2"></line>
                            <line class="st268" x1="215" y1="213" x2="149.4" y2="252.2"></line>
                            <line class="st269" x1="214.5" y1="214.4" x2="148" y2="252.2"></line>
                            <line class="st269" x1="214" y1="215.8" x2="146.6" y2="252.2"></line>
                            <line class="st270" x1="213.5" y1="217.2" x2="145.2" y2="252.2"></line>
                            <line class="st271" x1="213" y1="218.6" x2="143.8" y2="252.2"></line>
                            <line class="st272" x1="212.5" y1="220" x2="142.4" y2="252.2"></line>
                            <line class="st273" x1="212" y1="221.4" x2="141" y2="252.2"></line>
                            <line class="st274" x1="211.5" y1="222.8" x2="139.6" y2="252.2"></line>
                            <line class="st274" x1="211" y1="224.2" x2="138.2" y2="252.2"></line>
                            <line class="st275" x1="210.5" y1="225.6" x2="136.8" y2="252.2"></line>
                            <line class="st276" x1="210" y1="227" x2="135.4" y2="252.2"></line>
                            <line class="st277" x1="209.5" y1="228.4" x2="134" y2="252.2"></line>
                            <line class="st278" x1="209" y1="229.8" x2="132.6" y2="252.2"></line>
                            <line class="st279" x1="208.5" y1="231.2" x2="131.2" y2="252.2"></line>
                            <line class="st279" x1="208" y1="232.6" x2="129.8" y2="252.2"></line>
                            <line class="st280" x1="207.5" y1="234" x2="128.4" y2="252.2"></line>
                            <line class="st281" x1="207" y1="235.4" x2="127" y2="252.2"></line>
                            <line class="st282" x1="206.5" y1="236.8" x2="125.6" y2="252.2"></line>
                            <line class="st283" x1="206" y1="238.2" x2="124.2" y2="252.2"></line>
                            <line class="st284" x1="205.5" y1="239.6" x2="122.8" y2="252.2"></line>
                            <line class="st284" x1="205" y1="241" x2="121.4" y2="252.2"></line>
                            <line class="st285" x1="204.5" y1="242.4" x2="120" y2="252.2"></line>
                            <line class="st286" x1="204" y1="243.8" x2="118.6" y2="252.2"></line>
                            <line class="st287" x1="203.5" y1="245.2" x2="117.2" y2="252.2"></line>
                            <line class="st288" x1="203" y1="246.6" x2="115.8" y2="252.2"></line>
                            <line class="st289" x1="202.5" y1="248" x2="114.4" y2="252.2"></line>
                            <line class="st290" x1="202" y1="249.4" x2="113" y2="252.2"></line>
                            <line class="st291" x1="201.5" y1="250.8" x2="111.6" y2="252.2"></line>
                            <line class="st203" x1="251.6" y1="393.6" x2="251.6" y2="252.2"></line>
                            <line class="st204" x1="251.1" y1="392.2" x2="250.2" y2="252.2"></line>
                            <line class="st205" x1="250.6" y1="390.8" x2="248.8" y2="252.2"></line>
                            <line class="st206" x1="250.1" y1="389.4" x2="247.4" y2="252.2"></line>
                            <line class="st207" x1="249.6" y1="388" x2="246" y2="252.2"></line>
                            <line class="st208" x1="249.1" y1="386.6" x2="244.6" y2="252.2"></line>
                            <line class="st209" x1="248.6" y1="385.2" x2="243.2" y2="252.2"></line>
                            <line class="st210" x1="248.1" y1="383.8" x2="241.8" y2="252.2"></line>
                            <line class="st211" x1="247.6" y1="382.4" x2="240.4" y2="252.2"></line>
                            <line class="st211" x1="247.1" y1="381" x2="239" y2="252.2"></line>
                            <line class="st212" x1="246.6" y1="379.6" x2="237.6" y2="252.2"></line>
                            <line class="st213" x1="246.1" y1="378.2" x2="236.2" y2="252.2"></line>
                            <line class="st214" x1="245.6" y1="376.8" x2="234.8" y2="252.2"></line>
                            <line class="st215" x1="245.1" y1="375.4" x2="233.4" y2="252.2"></line>
                            <line class="st216" x1="244.6" y1="374" x2="232" y2="252.2"></line>
                            <line class="st216" x1="244.1" y1="372.6" x2="230.6" y2="252.2"></line>
                            <line class="st217" x1="243.6" y1="371.2" x2="229.2" y2="252.2"></line>
                            <line class="st218" x1="243.1" y1="369.8" x2="227.8" y2="252.2"></line>
                            <line class="st219" x1="242.6" y1="368.4" x2="226.4" y2="252.2"></line>
                            <line class="st220" x1="242.1" y1="367" x2="225" y2="252.2"></line>
                            <line class="st221" x1="241.6" y1="365.6" x2="223.6" y2="252.2"></line>
                            <line class="st221" x1="241.1" y1="364.2" x2="222.2" y2="252.2"></line>
                            <line class="st222" x1="240.6" y1="362.8" x2="220.8" y2="252.2"></line>
                            <line class="st223" x1="240.1" y1="361.4" x2="219.4" y2="252.2"></line>
                            <line class="st224" x1="239.6" y1="360" x2="218" y2="252.2"></line>
                            <line class="st225" x1="239.1" y1="358.6" x2="216.6" y2="252.2"></line>
                            <line class="st226" x1="238.6" y1="357.2" x2="215.2" y2="252.2"></line>
                            <line class="st226" x1="238" y1="355.8" x2="213.8" y2="252.2"></line>
                            <line class="st227" x1="237.5" y1="354.4" x2="212.4" y2="252.2"></line>
                            <line class="st228" x1="237" y1="353" x2="211" y2="252.2"></line>
                            <line class="st229" x1="236.5" y1="351.6" x2="209.6" y2="252.2"></line>
                            <line class="st230" x1="236" y1="350.2" x2="208.2" y2="252.2"></line>
                            <line class="st231" x1="235.5" y1="348.8" x2="206.8" y2="252.2"></line>
                            <line class="st232" x1="235" y1="347.4" x2="205.4" y2="252.2"></line>
                            <line class="st233" x1="234.5" y1="346" x2="204" y2="252.2"></line>
                            <line class="st234" x1="234" y1="344.6" x2="202.6" y2="252.2"></line>
                            <line class="st235" x1="233.5" y1="343.2" x2="201.2" y2="252.2"></line>
                            <line class="st236" x1="233" y1="341.8" x2="199.8" y2="252.2"></line>
                            <line class="st237" x1="232.5" y1="340.4" x2="198.4" y2="252.2"></line>
                            <line class="st237" x1="232" y1="339" x2="197" y2="252.2"></line>
                            <line class="st238" x1="231.5" y1="337.6" x2="195.6" y2="252.2"></line>
                            <line class="st239" x1="231" y1="336.2" x2="194.2" y2="252.2"></line>
                            <line class="st240" x1="230.5" y1="334.8" x2="192.8" y2="252.2"></line>
                            <line class="st241" x1="230" y1="333.4" x2="191.4" y2="252.2"></line>
                            <line class="st242" x1="229.5" y1="332" x2="190" y2="252.2"></line>
                            <line class="st242" x1="229" y1="330.6" x2="188.6" y2="252.2"></line>
                            <line class="st243" x1="228.5" y1="329.2" x2="187.2" y2="252.2"></line>
                            <line class="st244" x1="228" y1="327.8" x2="185.8" y2="252.2"></line>
                            <line class="st245" x1="227.5" y1="326.4" x2="184.4" y2="252.2"></line>
                            <line class="st246" x1="227" y1="325" x2="183" y2="252.2"></line>
                            <line class="st247" x1="226.5" y1="323.6" x2="181.6" y2="252.2"></line>
                            <line class="st248" x1="226" y1="322.2" x2="180.2" y2="252.2"></line>
                            <line class="st249" x1="225.5" y1="320.8" x2="178.8" y2="252.2"></line>
                            <line class="st250" x1="225" y1="319.4" x2="177.4" y2="252.2"></line>
                            <line class="st251" x1="224.5" y1="318" x2="176" y2="252.2"></line>
                            <line class="st252" x1="224" y1="316.6" x2="174.6" y2="252.2"></line>
                            <line class="st253" x1="223.5" y1="315.2" x2="173.2" y2="252.2"></line>
                            <line class="st253" x1="223" y1="313.8" x2="171.8" y2="252.2"></line>
                            <line class="st254" x1="222.5" y1="312.4" x2="170.4" y2="252.2"></line>
                            <line class="st255" x1="222" y1="311" x2="169" y2="252.2"></line>
                            <line class="st256" x1="221.5" y1="309.6" x2="167.6" y2="252.2"></line>
                            <line class="st257" x1="221" y1="308.2" x2="166.2" y2="252.2"></line>
                            <line class="st258" x1="220.5" y1="306.8" x2="164.8" y2="252.2"></line>
                            <line class="st258" x1="220" y1="305.4" x2="163.4" y2="252.2"></line>
                            <line class="st259" x1="219.5" y1="304" x2="162" y2="252.2"></line>
                            <line class="st260" x1="219" y1="302.6" x2="160.6" y2="252.2"></line>
                            <line class="st261" x1="218.5" y1="301.2" x2="159.2" y2="252.2"></line>
                            <line class="st262" x1="218" y1="299.8" x2="157.8" y2="252.2"></line>
                            <line class="st263" x1="217.5" y1="298.4" x2="156.4" y2="252.2"></line>
                            <line class="st264" x1="217" y1="297" x2="155" y2="252.2"></line>
                            <line class="st265" x1="216.5" y1="295.6" x2="153.6" y2="252.2"></line>
                            <line class="st266" x1="216" y1="294.2" x2="152.2" y2="252.2"></line>
                            <line class="st267" x1="215.5" y1="292.8" x2="150.8" y2="252.2"></line>
                            <line class="st268" x1="215" y1="291.4" x2="149.4" y2="252.2"></line>
                            <line class="st269" x1="214.5" y1="290" x2="148" y2="252.2"></line>
                            <line class="st269" x1="214" y1="288.6" x2="146.6" y2="252.2"></line>
                            <line class="st270" x1="213.5" y1="287.2" x2="145.2" y2="252.2"></line>
                            <line class="st271" x1="213" y1="285.8" x2="143.8" y2="252.2"></line>
                            <line class="st272" x1="212.5" y1="284.4" x2="142.4" y2="252.2"></line>
                            <line class="st273" x1="212" y1="283" x2="141" y2="252.2"></line>
                            <line class="st274" x1="211.5" y1="281.6" x2="139.6" y2="252.2"></line>
                            <line class="st274" x1="211" y1="280.2" x2="138.2" y2="252.2"></line>
                            <line class="st275" x1="210.5" y1="278.8" x2="136.8" y2="252.2"></line>
                            <line class="st276" x1="210" y1="277.4" x2="135.4" y2="252.2"></line>
                            <line class="st277" x1="209.5" y1="276" x2="134" y2="252.2"></line>
                            <line class="st278" x1="209" y1="274.6" x2="132.6" y2="252.2"></line>
                            <line class="st279" x1="208.5" y1="273.2" x2="131.2" y2="252.2"></line>
                            <line class="st279" x1="208" y1="271.8" x2="129.8" y2="252.2"></line>
                            <line class="st280" x1="207.5" y1="270.4" x2="128.4" y2="252.2"></line>
                            <line class="st281" x1="207" y1="269" x2="127" y2="252.2"></line>
                            <line class="st282" x1="206.5" y1="267.6" x2="125.6" y2="252.2"></line>
                            <line class="st283" x1="206" y1="266.2" x2="124.2" y2="252.2"></line>
                            <line class="st284" x1="205.5" y1="264.8" x2="122.8" y2="252.2"></line>
                            <line class="st284" x1="205" y1="263.4" x2="121.4" y2="252.2"></line>
                            <line class="st285" x1="204.5" y1="262" x2="120" y2="252.2"></line>
                            <line class="st286" x1="204" y1="260.6" x2="118.6" y2="252.2"></line>
                            <line class="st287" x1="203.5" y1="259.2" x2="117.2" y2="252.2"></line>
                            <line class="st288" x1="203" y1="257.8" x2="115.8" y2="252.2"></line>
                            <line class="st289" x1="202.5" y1="256.4" x2="114.4" y2="252.2"></line>
                            <line class="st290" x1="202" y1="255" x2="113" y2="252.2"></line>
                            <line class="st291" x1="201.5" y1="253.6" x2="111.6" y2="252.2"></line>
                            <line class="st292" x1="252.1" y1="392.2" x2="253" y2="252.2"></line>
                            <line class="st293" x1="252.6" y1="390.8" x2="254.4" y2="252.2"></line>
                            <line class="st294" x1="253.1" y1="389.4" x2="255.8" y2="252.2"></line>
                            <line class="st295" x1="253.6" y1="388" x2="257.2" y2="252.2"></line>
                            <line class="st296" x1="254.1" y1="386.6" x2="258.6" y2="252.2"></line>
                            <line class="st297" x1="254.6" y1="385.2" x2="260" y2="252.2"></line>
                            <line class="st298" x1="255.1" y1="383.8" x2="261.4" y2="252.2"></line>
                            <line class="st299" x1="255.6" y1="382.4" x2="262.8" y2="252.2"></line>
                            <line class="st300" x1="256.1" y1="381" x2="264.2" y2="252.2"></line>
                            <line class="st301" x1="256.6" y1="379.6" x2="265.6" y2="252.2"></line>
                            <line class="st302" x1="257.1" y1="378.2" x2="267" y2="252.2"></line>
                            <line class="st303" x1="257.6" y1="376.8" x2="268.4" y2="252.2"></line>
                            <line class="st304" x1="258.1" y1="375.4" x2="269.8" y2="252.2"></line>
                            <line class="st305" x1="258.6" y1="374" x2="271.2" y2="252.2"></line>
                            <line class="st306" x1="259.1" y1="372.6" x2="272.6" y2="252.2"></line>
                            <line class="st307" x1="259.6" y1="371.2" x2="274" y2="252.2"></line>
                            <line class="st308" x1="260.1" y1="369.8" x2="275.4" y2="252.2"></line>
                            <line class="st309" x1="260.6" y1="368.4" x2="276.8" y2="252.2"></line>
                            <line class="st310" x1="261.1" y1="367" x2="278.2" y2="252.2"></line>
                            <line class="st311" x1="261.6" y1="365.6" x2="279.6" y2="252.2"></line>
                            <line class="st312" x1="262.1" y1="364.2" x2="281" y2="252.2"></line>
                            <line class="st313" x1="262.6" y1="362.8" x2="282.4" y2="252.2"></line>
                            <line class="st314" x1="263.1" y1="361.4" x2="283.8" y2="252.2"></line>
                            <line class="st315" x1="263.6" y1="360" x2="285.2" y2="252.2"></line>
                            <line class="st316" x1="264.1" y1="358.6" x2="286.6" y2="252.2"></line>
                            <line class="st317" x1="264.6" y1="357.2" x2="288" y2="252.2"></line>
                            <line class="st318" x1="265.1" y1="355.8" x2="289.4" y2="252.2"></line>
                            <line class="st319" x1="265.6" y1="354.4" x2="290.8" y2="252.2"></line>
                            <line class="st320" x1="266.1" y1="353" x2="292.2" y2="252.2"></line>
                            <line class="st321" x1="266.6" y1="351.6" x2="293.6" y2="252.2"></line>
                            <line class="st322" x1="267.1" y1="350.2" x2="295" y2="252.2"></line>
                            <line class="st323" x1="267.6" y1="348.8" x2="296.4" y2="252.2"></line>
                            <line class="st324" x1="268.1" y1="347.4" x2="297.8" y2="252.2"></line>
                            <line class="st325" x1="268.6" y1="346" x2="299.2" y2="252.2"></line>
                            <line class="st326" x1="269.1" y1="344.6" x2="300.6" y2="252.2"></line>
                            <line class="st327" x1="269.6" y1="343.2" x2="302" y2="252.2"></line>
                            <line class="st328" x1="270.1" y1="341.8" x2="303.4" y2="252.2"></line>
                            <line class="st329" x1="270.6" y1="340.4" x2="304.8" y2="252.2"></line>
                            <line class="st330" x1="271.1" y1="339" x2="306.2" y2="252.2"></line>
                            <line class="st331" x1="271.6" y1="337.6" x2="307.6" y2="252.2"></line>
                            <line class="st332" x1="272.1" y1="336.2" x2="309" y2="252.2"></line>
                            <line class="st333" x1="272.6" y1="334.8" x2="310.4" y2="252.2"></line>
                            <line class="st334" x1="273.1" y1="333.4" x2="311.8" y2="252.2"></line>
                            <line class="st335" x1="273.6" y1="332" x2="313.2" y2="252.2"></line>
                            <line class="st336" x1="274.1" y1="330.6" x2="314.6" y2="252.2"></line>
                            <line class="st337" x1="274.6" y1="329.2" x2="316" y2="252.2"></line>
                            <line class="st338" x1="275.1" y1="327.8" x2="317.4" y2="252.2"></line>
                            <line class="st339" x1="275.6" y1="326.4" x2="318.8" y2="252.2"></line>
                            <line class="st340" x1="276.2" y1="325" x2="320.2" y2="252.2"></line>
                            <line class="st341" x1="276.7" y1="323.6" x2="321.6" y2="252.2"></line>
                            <line class="st342" x1="277.2" y1="322.2" x2="323" y2="252.2"></line>
                            <line class="st343" x1="277.7" y1="320.8" x2="324.4" y2="252.2"></line>
                            <line class="st344" x1="278.2" y1="319.4" x2="325.8" y2="252.2"></line>
                            <line class="st345" x1="278.7" y1="318" x2="327.2" y2="252.2"></line>
                            <line class="st346" x1="279.2" y1="316.6" x2="328.6" y2="252.2"></line>
                            <line class="st347" x1="279.7" y1="315.2" x2="330" y2="252.2"></line>
                            <line class="st348" x1="280.2" y1="313.8" x2="331.4" y2="252.2"></line>
                            <line class="st349" x1="280.7" y1="312.4" x2="332.8" y2="252.2"></line>
                            <line class="st350" x1="281.2" y1="311" x2="334.2" y2="252.2"></line>
                            <line class="st351" x1="281.7" y1="309.6" x2="335.6" y2="252.2"></line>
                            <line class="st352" x1="282.2" y1="308.2" x2="337" y2="252.2"></line>
                            <line class="st353" x1="282.7" y1="306.8" x2="338.4" y2="252.2"></line>
                            <line class="st354" x1="283.2" y1="305.4" x2="339.8" y2="252.2"></line>
                            <line class="st355" x1="283.7" y1="304" x2="341.2" y2="252.2"></line>
                            <line class="st356" x1="284.2" y1="302.6" x2="342.6" y2="252.2"></line>
                            <line class="st357" x1="284.7" y1="301.2" x2="344" y2="252.2"></line>
                            <line class="st358" x1="285.2" y1="299.8" x2="345.4" y2="252.2"></line>
                            <line class="st359" x1="285.7" y1="298.4" x2="346.8" y2="252.2"></line>
                            <line class="st360" x1="286.2" y1="297" x2="348.2" y2="252.2"></line>
                            <line class="st361" x1="286.7" y1="295.6" x2="349.6" y2="252.2"></line>
                            <line class="st362" x1="287.2" y1="294.2" x2="351" y2="252.2"></line>
                            <line class="st363" x1="287.7" y1="292.8" x2="352.4" y2="252.2"></line>
                            <line class="st364" x1="288.2" y1="291.4" x2="353.8" y2="252.2"></line>
                            <line class="st365" x1="288.7" y1="290" x2="355.2" y2="252.2"></line>
                            <line class="st366" x1="289.2" y1="288.6" x2="356.6" y2="252.2"></line>
                            <line class="st367" x1="289.7" y1="287.2" x2="358" y2="252.2"></line>
                            <line class="st368" x1="290.2" y1="285.8" x2="359.4" y2="252.2"></line>
                            <line class="st369" x1="290.7" y1="284.4" x2="360.8" y2="252.2"></line>
                            <line class="st370" x1="291.2" y1="283" x2="362.2" y2="252.2"></line>
                            <line class="st371" x1="291.7" y1="281.6" x2="363.6" y2="252.2"></line>
                            <line class="st372" x1="292.2" y1="280.2" x2="365" y2="252.2"></line>
                            <line class="st373" x1="292.7" y1="278.8" x2="366.4" y2="252.2"></line>
                            <line class="st374" x1="293.2" y1="277.4" x2="367.8" y2="252.2"></line>
                            <line class="st375" x1="293.7" y1="276" x2="369.2" y2="252.2"></line>
                            <line class="st376" x1="294.2" y1="274.6" x2="370.6" y2="252.2"></line>
                            <line class="st377" x1="294.7" y1="273.2" x2="372" y2="252.2"></line>
                            <line class="st378" x1="295.2" y1="271.8" x2="373.4" y2="252.2"></line>
                            <line class="st379" x1="295.7" y1="270.4" x2="374.8" y2="252.2"></line>
                            <line class="st380" x1="296.2" y1="269" x2="376.2" y2="252.2"></line>
                            <line class="st381" x1="296.7" y1="267.6" x2="377.6" y2="252.2"></line>
                            <line class="st382" x1="297.2" y1="266.2" x2="379" y2="252.2"></line>
                            <line class="st383" x1="297.7" y1="264.8" x2="380.4" y2="252.2"></line>
                            <line class="st384" x1="298.2" y1="263.4" x2="381.8" y2="252.2"></line>
                            <line class="st385" x1="298.7" y1="262" x2="383.2" y2="252.2"></line>
                            <line class="st386" x1="299.2" y1="260.6" x2="384.6" y2="252.2"></line>
                            <line class="st387" x1="299.7" y1="259.2" x2="386" y2="252.2"></line>
                            <line class="st388" x1="300.2" y1="257.8" x2="387.4" y2="252.2"></line>
                            <line class="st389" x1="300.7" y1="256.4" x2="388.8" y2="252.2"></line>
                            <line class="st390" x1="301.2" y1="255" x2="390.2" y2="252.2"></line>
                            <line class="st391" x1="301.7" y1="253.6" x2="391.6" y2="252.2"></line>
                            <line class="st292" x1="252.1" y1="112.2" x2="253" y2="252.2"></line>
                            <line class="st293" x1="252.6" y1="113.6" x2="254.4" y2="252.2"></line>
                            <line class="st294" x1="253.1" y1="115" x2="255.8" y2="252.2"></line>
                            <line class="st295" x1="253.6" y1="116.4" x2="257.2" y2="252.2"></line>
                            <line class="st296" x1="254.1" y1="117.8" x2="258.6" y2="252.2"></line>
                            <line class="st297" x1="254.6" y1="119.2" x2="260" y2="252.2"></line>
                            <line class="st298" x1="255.1" y1="120.6" x2="261.4" y2="252.2"></line>
                            <line class="st299" x1="255.6" y1="122" x2="262.8" y2="252.2"></line>
                            <line class="st300" x1="256.1" y1="123.4" x2="264.2" y2="252.2"></line>
                            <line class="st301" x1="256.6" y1="124.8" x2="265.6" y2="252.2"></line>
                            <line class="st302" x1="257.1" y1="126.2" x2="267" y2="252.2"></line>
                            <line class="st303" x1="257.6" y1="127.6" x2="268.4" y2="252.2"></line>
                            <line class="st304" x1="258.1" y1="129" x2="269.8" y2="252.2"></line>
                            <line class="st305" x1="258.6" y1="130.4" x2="271.2" y2="252.2"></line>
                            <line class="st306" x1="259.1" y1="131.8" x2="272.6" y2="252.2"></line>
                            <line class="st307" x1="259.6" y1="133.2" x2="274" y2="252.2"></line>
                            <line class="st308" x1="260.1" y1="134.6" x2="275.4" y2="252.2"></line>
                            <line class="st309" x1="260.6" y1="136" x2="276.8" y2="252.2"></line>
                            <line class="st310" x1="261.1" y1="137.4" x2="278.2" y2="252.2"></line>
                            <line class="st311" x1="261.6" y1="138.8" x2="279.6" y2="252.2"></line>
                            <line class="st312" x1="262.1" y1="140.2" x2="281" y2="252.2"></line>
                            <line class="st313" x1="262.6" y1="141.6" x2="282.4" y2="252.2"></line>
                            <line class="st314" x1="263.1" y1="143" x2="283.8" y2="252.2"></line>
                            <line class="st315" x1="263.6" y1="144.4" x2="285.2" y2="252.2"></line>
                            <line class="st316" x1="264.1" y1="145.8" x2="286.6" y2="252.2"></line>
                            <line class="st317" x1="264.6" y1="147.2" x2="288" y2="252.2"></line>
                            <line class="st318" x1="265.1" y1="148.6" x2="289.4" y2="252.2"></line>
                            <line class="st319" x1="265.6" y1="150" x2="290.8" y2="252.2"></line>
                            <line class="st320" x1="266.1" y1="151.4" x2="292.2" y2="252.2"></line>
                            <line class="st321" x1="266.6" y1="152.8" x2="293.6" y2="252.2"></line>
                            <line class="st322" x1="267.1" y1="154.2" x2="295" y2="252.2"></line>
                            <line class="st323" x1="267.6" y1="155.6" x2="296.4" y2="252.2"></line>
                            <line class="st324" x1="268.1" y1="157" x2="297.8" y2="252.2"></line>
                            <line class="st325" x1="268.6" y1="158.4" x2="299.2" y2="252.2"></line>
                            <line class="st326" x1="269.1" y1="159.8" x2="300.6" y2="252.2"></line>
                            <line class="st327" x1="269.6" y1="161.2" x2="302" y2="252.2"></line>
                            <line class="st328" x1="270.1" y1="162.6" x2="303.4" y2="252.2"></line>
                            <line class="st329" x1="270.6" y1="164" x2="304.8" y2="252.2"></line>
                            <line class="st330" x1="271.1" y1="165.4" x2="306.2" y2="252.2"></line>
                            <line class="st331" x1="271.6" y1="166.8" x2="307.6" y2="252.2"></line>
                            <line class="st332" x1="272.1" y1="168.2" x2="309" y2="252.2"></line>
                            <line class="st333" x1="272.6" y1="169.6" x2="310.4" y2="252.2"></line>
                            <line class="st334" x1="273.1" y1="171" x2="311.8" y2="252.2"></line>
                            <line class="st335" x1="273.6" y1="172.4" x2="313.2" y2="252.2"></line>
                            <line class="st336" x1="274.1" y1="173.8" x2="314.6" y2="252.2"></line>
                            <line class="st337" x1="274.6" y1="175.2" x2="316" y2="252.2"></line>
                            <line class="st338" x1="275.1" y1="176.6" x2="317.4" y2="252.2"></line>
                            <line class="st339" x1="275.6" y1="178" x2="318.8" y2="252.2"></line>
                            <line class="st340" x1="276.2" y1="179.4" x2="320.2" y2="252.2"></line>
                            <line class="st341" x1="276.7" y1="180.8" x2="321.6" y2="252.2"></line>
                            <line class="st342" x1="277.2" y1="182.2" x2="323" y2="252.2"></line>
                            <line class="st343" x1="277.7" y1="183.6" x2="324.4" y2="252.2"></line>
                            <line class="st344" x1="278.2" y1="185" x2="325.8" y2="252.2"></line>
                            <line class="st345" x1="278.7" y1="186.4" x2="327.2" y2="252.2"></line>
                            <line class="st346" x1="279.2" y1="187.8" x2="328.6" y2="252.2"></line>
                            <line class="st347" x1="279.7" y1="189.2" x2="330" y2="252.2"></line>
                            <line class="st348" x1="280.2" y1="190.6" x2="331.4" y2="252.2"></line>
                            <line class="st349" x1="280.7" y1="192" x2="332.8" y2="252.2"></line>
                            <line class="st350" x1="281.2" y1="193.4" x2="334.2" y2="252.2"></line>
                            <line class="st351" x1="281.7" y1="194.8" x2="335.6" y2="252.2"></line>
                            <line class="st352" x1="282.2" y1="196.2" x2="337" y2="252.2"></line>
                            <line class="st353" x1="282.7" y1="197.6" x2="338.4" y2="252.2"></line>
                            <line class="st354" x1="283.2" y1="199" x2="339.8" y2="252.2"></line>
                            <line class="st355" x1="283.7" y1="200.4" x2="341.2" y2="252.2"></line>
                            <line class="st356" x1="284.2" y1="201.8" x2="342.6" y2="252.2"></line>
                            <line class="st357" x1="284.7" y1="203.2" x2="344" y2="252.2"></line>
                            <line class="st358" x1="285.2" y1="204.6" x2="345.4" y2="252.2"></line>
                            <line class="st359" x1="285.7" y1="206" x2="346.8" y2="252.2"></line>
                            <line class="st360" x1="286.2" y1="207.4" x2="348.2" y2="252.2"></line>
                            <line class="st361" x1="286.7" y1="208.8" x2="349.6" y2="252.2"></line>
                            <line class="st362" x1="287.2" y1="210.2" x2="351" y2="252.2"></line>
                            <line class="st363" x1="287.7" y1="211.6" x2="352.4" y2="252.2"></line>
                            <line class="st364" x1="288.2" y1="213" x2="353.8" y2="252.2"></line>
                            <line class="st365" x1="288.7" y1="214.4" x2="355.2" y2="252.2"></line>
                            <line class="st366" x1="289.2" y1="215.8" x2="356.6" y2="252.2"></line>
                            <line class="st367" x1="289.7" y1="217.2" x2="358" y2="252.2"></line>
                            <line class="st368" x1="290.2" y1="218.6" x2="359.4" y2="252.2"></line>
                            <line class="st369" x1="290.7" y1="220" x2="360.8" y2="252.2"></line>
                            <line class="st370" x1="291.2" y1="221.4" x2="362.2" y2="252.2"></line>
                            <line class="st371" x1="291.7" y1="222.8" x2="363.6" y2="252.2"></line>
                            <line class="st372" x1="292.2" y1="224.2" x2="365" y2="252.2"></line>
                            <line class="st373" x1="292.7" y1="225.6" x2="366.4" y2="252.2"></line>
                            <line class="st374" x1="293.2" y1="227" x2="367.8" y2="252.2"></line>
                            <line class="st375" x1="293.7" y1="228.4" x2="369.2" y2="252.2"></line>
                            <line class="st376" x1="294.2" y1="229.8" x2="370.6" y2="252.2"></line>
                            <line class="st377" x1="294.7" y1="231.2" x2="372" y2="252.2"></line>
                            <line class="st378" x1="295.2" y1="232.6" x2="373.4" y2="252.2"></line>
                            <line class="st379" x1="295.7" y1="234" x2="374.8" y2="252.2"></line>
                            <line class="st380" x1="296.2" y1="235.4" x2="376.2" y2="252.2"></line>
                            <line class="st381" x1="296.7" y1="236.8" x2="377.6" y2="252.2"></line>
                            <line class="st382" x1="297.2" y1="238.2" x2="379" y2="252.2"></line>
                            <line class="st383" x1="297.7" y1="239.6" x2="380.4" y2="252.2"></line>
                            <line class="st384" x1="298.2" y1="241" x2="381.8" y2="252.2"></line>
                            <line class="st385" x1="298.7" y1="242.4" x2="383.2" y2="252.2"></line>
                            <line class="st386" x1="299.2" y1="243.8" x2="384.6" y2="252.2"></line>
                            <line class="st387" x1="299.7" y1="245.2" x2="386" y2="252.2"></line>
                            <line class="st388" x1="300.2" y1="246.6" x2="387.4" y2="252.2"></line>
                            <line class="st389" x1="300.7" y1="248" x2="388.8" y2="252.2"></line>
                            <line class="st390" x1="301.2" y1="249.4" x2="390.2" y2="252.2"></line>
                            <line class="st391" x1="301.7" y1="250.8" x2="391.6" y2="252.2"></line>
                            <line class="st392" x1="110.2" y1="252.2" x2="201" y2="252.2"></line>
                            <line class="st393" x1="393" y1="252.2" x2="302.2" y2="252.2"></line>
                        </g>
                    </g>
                    <g id="Layer_2" className={executeAnime ? 'Layer3_Execute' : ''}>
                        <g>
                            <line class="st203" x1="251.6" y1="194.6" x2="251.6" y2="252.2"></line>
                            <line class="st292" x1="251.4" y1="195.2" x2="251" y2="252.2"></line>
                            <line class="st293" x1="251.2" y1="195.8" x2="250.4" y2="252.2"></line>
                            <line class="st294" x1="251" y1="196.4" x2="249.9" y2="252.2"></line>
                            <line class="st295" x1="250.8" y1="196.9" x2="249.3" y2="252.2"></line>
                            <line class="st296" x1="250.6" y1="197.5" x2="248.7" y2="252.2"></line>
                            <line class="st297" x1="250.4" y1="198.1" x2="248.2" y2="252.2"></line>
                            <line class="st298" x1="250.2" y1="198.6" x2="247.6" y2="252.2"></line>
                            <line class="st299" x1="250" y1="199.2" x2="247" y2="252.2"></line>
                            <line class="st300" x1="249.7" y1="199.8" x2="246.5" y2="252.2"></line>
                            <line class="st301" x1="249.5" y1="200.3" x2="245.9" y2="252.2"></line>
                            <line class="st302" x1="249.3" y1="200.9" x2="245.3" y2="252.2"></line>
                            <line class="st303" x1="249.1" y1="201.5" x2="244.7" y2="252.2"></line>
                            <line class="st304" x1="248.9" y1="202.1" x2="244.2" y2="252.2"></line>
                            <line class="st305" x1="248.7" y1="202.6" x2="243.6" y2="252.2"></line>
                            <line class="st306" x1="248.5" y1="203.2" x2="243" y2="252.2"></line>
                            <line class="st307" x1="248.3" y1="203.8" x2="242.5" y2="252.2"></line>
                            <line class="st308" x1="248.1" y1="204.3" x2="241.9" y2="252.2"></line>
                            <line class="st309" x1="247.9" y1="204.9" x2="241.3" y2="252.2"></line>
                            <line class="st310" x1="247.7" y1="205.5" x2="240.7" y2="252.2"></line>
                            <line class="st311" x1="247.5" y1="206.1" x2="240.2" y2="252.2"></line>
                            <line class="st312" x1="247.3" y1="206.6" x2="239.6" y2="252.2"></line>
                            <line class="st313" x1="247.1" y1="207.2" x2="239" y2="252.2"></line>
                            <line class="st314" x1="246.9" y1="207.8" x2="238.5" y2="252.2"></line>
                            <line class="st315" x1="246.7" y1="208.3" x2="237.9" y2="252.2"></line>
                            <line class="st316" x1="246.5" y1="208.9" x2="237.3" y2="252.2"></line>
                            <line class="st317" x1="246.3" y1="209.5" x2="236.8" y2="252.2"></line>
                            <line class="st318" x1="246.1" y1="210" x2="236.2" y2="252.2"></line>
                            <line class="st319" x1="245.9" y1="210.6" x2="235.6" y2="252.2"></line>
                            <line class="st320" x1="245.7" y1="211.2" x2="235" y2="252.2"></line>
                            <line class="st321" x1="245.5" y1="211.8" x2="234.5" y2="252.2"></line>
                            <line class="st322" x1="245.3" y1="212.3" x2="233.9" y2="252.2"></line>
                            <line class="st323" x1="245.1" y1="212.9" x2="233.3" y2="252.2"></line>
                            <line class="st324" x1="244.8" y1="213.5" x2="232.8" y2="252.2"></line>
                            <line class="st325" x1="244.6" y1="214" x2="232.2" y2="252.2"></line>
                            <line class="st326" x1="244.4" y1="214.6" x2="231.6" y2="252.2"></line>
                            <line class="st327" x1="244.2" y1="215.2" x2="231.1" y2="252.2"></line>
                            <line class="st328" x1="244" y1="215.7" x2="230.5" y2="252.2"></line>
                            <line class="st329" x1="243.8" y1="216.3" x2="229.9" y2="252.2"></line>
                            <line class="st330" x1="243.6" y1="216.9" x2="229.3" y2="252.2"></line>
                            <line class="st331" x1="243.4" y1="217.5" x2="228.8" y2="252.2"></line>
                            <line class="st332" x1="243.2" y1="218" x2="228.2" y2="252.2"></line>
                            <line class="st333" x1="243" y1="218.6" x2="227.6" y2="252.2"></line>
                            <line class="st334" x1="242.8" y1="219.2" x2="227.1" y2="252.2"></line>
                            <line class="st335" x1="242.6" y1="219.7" x2="226.5" y2="252.2"></line>
                            <line class="st336" x1="242.4" y1="220.3" x2="225.9" y2="252.2"></line>
                            <line class="st337" x1="242.2" y1="220.9" x2="225.4" y2="252.2"></line>
                            <line class="st338" x1="242" y1="221.4" x2="224.8" y2="252.2"></line>
                            <line class="st339" x1="241.8" y1="222" x2="224.2" y2="252.2"></line>
                            <line class="st340" x1="241.6" y1="222.6" x2="223.6" y2="252.2"></line>
                            <line class="st341" x1="241.4" y1="223.2" x2="223.1" y2="252.2"></line>
                            <line class="st342" x1="241.2" y1="223.7" x2="222.5" y2="252.2"></line>
                            <line class="st343" x1="241" y1="224.3" x2="221.9" y2="252.2"></line>
                            <line class="st344" x1="240.8" y1="224.9" x2="221.4" y2="252.2"></line>
                            <line class="st345" x1="240.6" y1="225.4" x2="220.8" y2="252.2"></line>
                            <line class="st346" x1="240.4" y1="226" x2="220.2" y2="252.2"></line>
                            <line class="st347" x1="240.1" y1="226.6" x2="219.6" y2="252.2"></line>
                            <line class="st348" x1="239.9" y1="227.2" x2="219.1" y2="252.2"></line>
                            <line class="st349" x1="239.7" y1="227.7" x2="218.5" y2="252.2"></line>
                            <line class="st350" x1="239.5" y1="228.3" x2="217.9" y2="252.2"></line>
                            <line class="st351" x1="239.3" y1="228.9" x2="217.4" y2="252.2"></line>
                            <line class="st352" x1="239.1" y1="229.4" x2="216.8" y2="252.2"></line>
                            <line class="st353" x1="238.9" y1="230" x2="216.2" y2="252.2"></line>
                            <line class="st354" x1="238.7" y1="230.6" x2="215.7" y2="252.2"></line>
                            <line class="st355" x1="238.5" y1="231.1" x2="215.1" y2="252.2"></line>
                            <line class="st356" x1="238.3" y1="231.7" x2="214.5" y2="252.2"></line>
                            <line class="st357" x1="238.1" y1="232.3" x2="213.9" y2="252.2"></line>
                            <line class="st358" x1="237.9" y1="232.9" x2="213.4" y2="252.2"></line>
                            <line class="st359" x1="237.7" y1="233.4" x2="212.8" y2="252.2"></line>
                            <line class="st360" x1="237.5" y1="234" x2="212.2" y2="252.2"></line>
                            <line class="st361" x1="237.3" y1="234.6" x2="211.7" y2="252.2"></line>
                            <line class="st362" x1="237.1" y1="235.1" x2="211.1" y2="252.2"></line>
                            <line class="st363" x1="236.9" y1="235.7" x2="210.5" y2="252.2"></line>
                            <line class="st364" x1="236.7" y1="236.3" x2="210" y2="252.2"></line>
                            <line class="st365" x1="236.5" y1="236.8" x2="209.4" y2="252.2"></line>
                            <line class="st366" x1="236.3" y1="237.4" x2="208.8" y2="252.2"></line>
                            <line class="st367" x1="236.1" y1="238" x2="208.2" y2="252.2"></line>
                            <line class="st368" x1="235.9" y1="238.6" x2="207.7" y2="252.2"></line>
                            <line class="st369" x1="235.7" y1="239.1" x2="207.1" y2="252.2"></line>
                            <line class="st370" x1="235.5" y1="239.7" x2="206.5" y2="252.2"></line>
                            <line class="st371" x1="235.2" y1="240.3" x2="206" y2="252.2"></line>
                            <line class="st372" x1="235" y1="240.8" x2="205.4" y2="252.2"></line>
                            <line class="st373" x1="234.8" y1="241.4" x2="204.8" y2="252.2"></line>
                            <line class="st374" x1="234.6" y1="242" x2="204.2" y2="252.2"></line>
                            <line class="st375" x1="234.4" y1="242.6" x2="203.7" y2="252.2"></line>
                            <line class="st376" x1="234.2" y1="243.1" x2="203.1" y2="252.2"></line>
                            <line class="st377" x1="234" y1="243.7" x2="202.5" y2="252.2"></line>
                            <line class="st378" x1="233.8" y1="244.3" x2="202" y2="252.2"></line>
                            <line class="st379" x1="233.6" y1="244.8" x2="201.4" y2="252.2"></line>
                            <line class="st380" x1="233.4" y1="245.4" x2="200.8" y2="252.2"></line>
                            <line class="st381" x1="233.2" y1="246" x2="200.3" y2="252.2"></line>
                            <line class="st382" x1="233" y1="246.5" x2="199.7" y2="252.2"></line>
                            <line class="st383" x1="232.8" y1="247.1" x2="199.1" y2="252.2"></line>
                            <line class="st384" x1="232.6" y1="247.7" x2="198.5" y2="252.2"></line>
                            <line class="st385" x1="232.4" y1="248.3" x2="198" y2="252.2"></line>
                            <line class="st386" x1="232.2" y1="248.8" x2="197.4" y2="252.2"></line>
                            <line class="st387" x1="232" y1="249.4" x2="196.8" y2="252.2"></line>
                            <line class="st388" x1="231.8" y1="250" x2="196.3" y2="252.2"></line>
                            <line class="st389" x1="231.6" y1="250.5" x2="195.7" y2="252.2"></line>
                            <line class="st390" x1="231.4" y1="251.1" x2="195.1" y2="252.2"></line>
                            <line class="st391" x1="231.2" y1="251.7" x2="194.6" y2="252.2"></line>
                            <line class="st203" x1="251.6" y1="309.8" x2="251.6" y2="252.2"></line>
                            <line class="st292" x1="251.4" y1="309.3" x2="251" y2="252.2"></line>
                            <line class="st293" x1="251.2" y1="308.7" x2="250.4" y2="252.2"></line>
                            <line class="st294" x1="251" y1="308.1" x2="249.9" y2="252.2"></line>
                            <line class="st295" x1="250.8" y1="307.6" x2="249.3" y2="252.2"></line>
                            <line class="st296" x1="250.6" y1="307" x2="248.7" y2="252.2"></line>
                            <line class="st297" x1="250.4" y1="306.4" x2="248.2" y2="252.2"></line>
                            <line class="st298" x1="250.2" y1="305.9" x2="247.6" y2="252.2"></line>
                            <line class="st299" x1="250" y1="305.3" x2="247" y2="252.2"></line>
                            <line class="st300" x1="249.7" y1="304.7" x2="246.5" y2="252.2"></line>
                            <line class="st301" x1="249.5" y1="304.1" x2="245.9" y2="252.2"></line>
                            <line class="st302" x1="249.3" y1="303.6" x2="245.3" y2="252.2"></line>
                            <line class="st303" x1="249.1" y1="303" x2="244.7" y2="252.2"></line>
                            <line class="st304" x1="248.9" y1="302.4" x2="244.2" y2="252.2"></line>
                            <line class="st305" x1="248.7" y1="301.9" x2="243.6" y2="252.2"></line>
                            <line class="st306" x1="248.5" y1="301.3" x2="243" y2="252.2"></line>
                            <line class="st307" x1="248.3" y1="300.7" x2="242.5" y2="252.2"></line>
                            <line class="st308" x1="248.1" y1="300.2" x2="241.9" y2="252.2"></line>
                            <line class="st309" x1="247.9" y1="299.6" x2="241.3" y2="252.2"></line>
                            <line class="st310" x1="247.7" y1="299" x2="240.7" y2="252.2"></line>
                            <line class="st311" x1="247.5" y1="298.4" x2="240.2" y2="252.2"></line>
                            <line class="st312" x1="247.3" y1="297.9" x2="239.6" y2="252.2"></line>
                            <line class="st313" x1="247.1" y1="297.3" x2="239" y2="252.2"></line>
                            <line class="st314" x1="246.9" y1="296.7" x2="238.5" y2="252.2"></line>
                            <line class="st315" x1="246.7" y1="296.2" x2="237.9" y2="252.2"></line>
                            <line class="st316" x1="246.5" y1="295.6" x2="237.3" y2="252.2"></line>
                            <line class="st317" x1="246.3" y1="295" x2="236.8" y2="252.2"></line>
                            <line class="st318" x1="246.1" y1="294.4" x2="236.2" y2="252.2"></line>
                            <line class="st319" x1="245.9" y1="293.9" x2="235.6" y2="252.2"></line>
                            <line class="st320" x1="245.7" y1="293.3" x2="235" y2="252.2"></line>
                            <line class="st321" x1="245.5" y1="292.7" x2="234.5" y2="252.2"></line>
                            <line class="st322" x1="245.3" y1="292.2" x2="233.9" y2="252.2"></line>
                            <line class="st323" x1="245.1" y1="291.6" x2="233.3" y2="252.2"></line>
                            <line class="st324" x1="244.8" y1="291" x2="232.8" y2="252.2"></line>
                            <line class="st325" x1="244.6" y1="290.5" x2="232.2" y2="252.2"></line>
                            <line class="st326" x1="244.4" y1="289.9" x2="231.6" y2="252.2"></line>
                            <line class="st327" x1="244.2" y1="289.3" x2="231.1" y2="252.2"></line>
                            <line class="st328" x1="244" y1="288.7" x2="230.5" y2="252.2"></line>
                            <line class="st329" x1="243.8" y1="288.2" x2="229.9" y2="252.2"></line>
                            <line class="st330" x1="243.6" y1="287.6" x2="229.3" y2="252.2"></line>
                            <line class="st331" x1="243.4" y1="287" x2="228.8" y2="252.2"></line>
                            <line class="st332" x1="243.2" y1="286.5" x2="228.2" y2="252.2"></line>
                            <line class="st333" x1="243" y1="285.9" x2="227.6" y2="252.2"></line>
                            <line class="st334" x1="242.8" y1="285.3" x2="227.1" y2="252.2"></line>
                            <line class="st335" x1="242.6" y1="284.8" x2="226.5" y2="252.2"></line>
                            <line class="st336" x1="242.4" y1="284.2" x2="225.9" y2="252.2"></line>
                            <line class="st337" x1="242.2" y1="283.6" x2="225.4" y2="252.2"></line>
                            <line class="st338" x1="242" y1="283" x2="224.8" y2="252.2"></line>
                            <line class="st339" x1="241.8" y1="282.5" x2="224.2" y2="252.2"></line>
                            <line class="st340" x1="241.6" y1="281.9" x2="223.6" y2="252.2"></line>
                            <line class="st341" x1="241.4" y1="281.3" x2="223.1" y2="252.2"></line>
                            <line class="st342" x1="241.2" y1="280.8" x2="222.5" y2="252.2"></line>
                            <line class="st343" x1="241" y1="280.2" x2="221.9" y2="252.2"></line>
                            <line class="st344" x1="240.8" y1="279.6" x2="221.4" y2="252.2"></line>
                            <line class="st345" x1="240.6" y1="279" x2="220.8" y2="252.2"></line>
                            <line class="st346" x1="240.4" y1="278.5" x2="220.2" y2="252.2"></line>
                            <line class="st347" x1="240.1" y1="277.9" x2="219.6" y2="252.2"></line>
                            <line class="st348" x1="239.9" y1="277.3" x2="219.1" y2="252.2"></line>
                            <line class="st349" x1="239.7" y1="276.8" x2="218.5" y2="252.2"></line>
                            <line class="st350" x1="239.5" y1="276.2" x2="217.9" y2="252.2"></line>
                            <line class="st351" x1="239.3" y1="275.6" x2="217.4" y2="252.2"></line>
                            <line class="st352" x1="239.1" y1="275.1" x2="216.8" y2="252.2"></line>
                            <line class="st353" x1="238.9" y1="274.5" x2="216.2" y2="252.2"></line>
                            <line class="st354" x1="238.7" y1="273.9" x2="215.7" y2="252.2"></line>
                            <line class="st355" x1="238.5" y1="273.3" x2="215.1" y2="252.2"></line>
                            <line class="st356" x1="238.3" y1="272.8" x2="214.5" y2="252.2"></line>
                            <line class="st357" x1="238.1" y1="272.2" x2="213.9" y2="252.2"></line>
                            <line class="st358" x1="237.9" y1="271.6" x2="213.4" y2="252.2"></line>
                            <line class="st359" x1="237.7" y1="271.1" x2="212.8" y2="252.2"></line>
                            <line class="st360" x1="237.5" y1="270.5" x2="212.2" y2="252.2"></line>
                            <line class="st361" x1="237.3" y1="269.9" x2="211.7" y2="252.2"></line>
                            <line class="st362" x1="237.1" y1="269.4" x2="211.1" y2="252.2"></line>
                            <line class="st363" x1="236.9" y1="268.8" x2="210.5" y2="252.2"></line>
                            <line class="st364" x1="236.7" y1="268.2" x2="210" y2="252.2"></line>
                            <line class="st365" x1="236.5" y1="267.6" x2="209.4" y2="252.2"></line>
                            <line class="st366" x1="236.3" y1="267.1" x2="208.8" y2="252.2"></line>
                            <line class="st367" x1="236.1" y1="266.5" x2="208.2" y2="252.2"></line>
                            <line class="st368" x1="235.9" y1="265.9" x2="207.7" y2="252.2"></line>
                            <line class="st369" x1="235.7" y1="265.4" x2="207.1" y2="252.2"></line>
                            <line class="st370" x1="235.5" y1="264.8" x2="206.5" y2="252.2"></line>
                            <line class="st371" x1="235.2" y1="264.2" x2="206" y2="252.2"></line>
                            <line class="st372" x1="235" y1="263.7" x2="205.4" y2="252.2"></line>
                            <line class="st373" x1="234.8" y1="263.1" x2="204.8" y2="252.2"></line>
                            <line class="st374" x1="234.6" y1="262.5" x2="204.2" y2="252.2"></line>
                            <line class="st375" x1="234.4" y1="261.9" x2="203.7" y2="252.2"></line>
                            <line class="st376" x1="234.2" y1="261.4" x2="203.1" y2="252.2"></line>
                            <line class="st377" x1="234" y1="260.8" x2="202.5" y2="252.2"></line>
                            <line class="st378" x1="233.8" y1="260.2" x2="202" y2="252.2"></line>
                            <line class="st379" x1="233.6" y1="259.7" x2="201.4" y2="252.2"></line>
                            <line class="st380" x1="233.4" y1="259.1" x2="200.8" y2="252.2"></line>
                            <line class="st381" x1="233.2" y1="258.5" x2="200.3" y2="252.2"></line>
                            <line class="st382" x1="233" y1="257.9" x2="199.7" y2="252.2"></line>
                            <line class="st383" x1="232.8" y1="257.4" x2="199.1" y2="252.2"></line>
                            <line class="st384" x1="232.6" y1="256.8" x2="198.5" y2="252.2"></line>
                            <line class="st385" x1="232.4" y1="256.2" x2="198" y2="252.2"></line>
                            <line class="st386" x1="232.2" y1="255.7" x2="197.4" y2="252.2"></line>
                            <line class="st387" x1="232" y1="255.1" x2="196.8" y2="252.2"></line>
                            <line class="st388" x1="231.8" y1="254.5" x2="196.3" y2="252.2"></line>
                            <line class="st389" x1="231.6" y1="254" x2="195.7" y2="252.2"></line>
                            <line class="st390" x1="231.4" y1="253.4" x2="195.1" y2="252.2"></line>
                            <line class="st391" x1="231.2" y1="252.8" x2="194.6" y2="252.2"></line>
                            <line class="st204" x1="251.8" y1="195.2" x2="252.2" y2="252.2"></line>
                            <line class="st205" x1="252" y1="195.8" x2="252.7" y2="252.2"></line>
                            <line class="st206" x1="252.2" y1="196.4" x2="253.3" y2="252.2"></line>
                            <line class="st207" x1="252.4" y1="196.9" x2="253.9" y2="252.2"></line>
                            <line class="st208" x1="252.6" y1="197.5" x2="254.4" y2="252.2"></line>
                            <line class="st209" x1="252.8" y1="198.1" x2="255" y2="252.2"></line>
                            <line class="st210" x1="253" y1="198.6" x2="255.6" y2="252.2"></line>
                            <line class="st211" x1="253.2" y1="199.2" x2="256.1" y2="252.2"></line>
                            <line class="st211" x1="253.4" y1="199.8" x2="256.7" y2="252.2"></line>
                            <line class="st212" x1="253.6" y1="200.3" x2="257.3" y2="252.2"></line>
                            <line class="st213" x1="253.8" y1="200.9" x2="257.9" y2="252.2"></line>
                            <line class="st214" x1="254" y1="201.5" x2="258.4" y2="252.2"></line>
                            <line class="st215" x1="254.2" y1="202.1" x2="259" y2="252.2"></line>
                            <line class="st216" x1="254.4" y1="202.6" x2="259.6" y2="252.2"></line>
                            <line class="st216" x1="254.6" y1="203.2" x2="260.1" y2="252.2"></line>
                            <line class="st217" x1="254.9" y1="203.8" x2="260.7" y2="252.2"></line>
                            <line class="st218" x1="255.1" y1="204.3" x2="261.3" y2="252.2"></line>
                            <line class="st219" x1="255.3" y1="204.9" x2="261.9" y2="252.2"></line>
                            <line class="st220" x1="255.5" y1="205.5" x2="262.4" y2="252.2"></line>
                            <line class="st221" x1="255.7" y1="206.1" x2="263" y2="252.2"></line>
                            <line class="st221" x1="255.9" y1="206.6" x2="263.6" y2="252.2"></line>
                            <line class="st222" x1="256.1" y1="207.2" x2="264.1" y2="252.2"></line>
                            <line class="st223" x1="256.3" y1="207.8" x2="264.7" y2="252.2"></line>
                            <line class="st224" x1="256.5" y1="208.3" x2="265.3" y2="252.2"></line>
                            <line class="st225" x1="256.7" y1="208.9" x2="265.8" y2="252.2"></line>
                            <line class="st226" x1="256.9" y1="209.5" x2="266.4" y2="252.2"></line>
                            <line class="st226" x1="257.1" y1="210" x2="267" y2="252.2"></line>
                            <line class="st227" x1="257.3" y1="210.6" x2="267.6" y2="252.2"></line>
                            <line class="st228" x1="257.5" y1="211.2" x2="268.1" y2="252.2"></line>
                            <line class="st229" x1="257.7" y1="211.8" x2="268.7" y2="252.2"></line>
                            <line class="st230" x1="257.9" y1="212.3" x2="269.3" y2="252.2"></line>
                            <line class="st231" x1="258.1" y1="212.9" x2="269.8" y2="252.2"></line>
                            <line class="st232" x1="258.3" y1="213.5" x2="270.4" y2="252.2"></line>
                            <line class="st233" x1="258.5" y1="214" x2="271" y2="252.2"></line>
                            <line class="st234" x1="258.7" y1="214.6" x2="271.5" y2="252.2"></line>
                            <line class="st235" x1="258.9" y1="215.2" x2="272.1" y2="252.2"></line>
                            <line class="st236" x1="259.1" y1="215.7" x2="272.7" y2="252.2"></line>
                            <line class="st237" x1="259.3" y1="216.3" x2="273.3" y2="252.2"></line>
                            <line class="st237" x1="259.5" y1="216.9" x2="273.8" y2="252.2"></line>
                            <line class="st238" x1="259.8" y1="217.5" x2="274.4" y2="252.2"></line>
                            <line class="st239" x1="260" y1="218" x2="275" y2="252.2"></line>
                            <line class="st240" x1="260.2" y1="218.6" x2="275.5" y2="252.2"></line>
                            <line class="st241" x1="260.4" y1="219.2" x2="276.1" y2="252.2"></line>
                            <line class="st242" x1="260.6" y1="219.7" x2="276.7" y2="252.2"></line>
                            <line class="st242" x1="260.8" y1="220.3" x2="277.2" y2="252.2"></line>
                            <line class="st243" x1="261" y1="220.9" x2="277.8" y2="252.2"></line>
                            <line class="st244" x1="261.2" y1="221.4" x2="278.4" y2="252.2"></line>
                            <line class="st245" x1="261.4" y1="222" x2="279" y2="252.2"></line>
                            <line class="st246" x1="261.6" y1="222.6" x2="279.5" y2="252.2"></line>
                            <line class="st247" x1="261.8" y1="223.2" x2="280.1" y2="252.2"></line>
                            <line class="st248" x1="262" y1="223.7" x2="280.7" y2="252.2"></line>
                            <line class="st249" x1="262.2" y1="224.3" x2="281.2" y2="252.2"></line>
                            <line class="st250" x1="262.4" y1="224.9" x2="281.8" y2="252.2"></line>
                            <line class="st251" x1="262.6" y1="225.4" x2="282.4" y2="252.2"></line>
                            <line class="st252" x1="262.8" y1="226" x2="283" y2="252.2"></line>
                            <line class="st253" x1="263" y1="226.6" x2="283.5" y2="252.2"></line>
                            <line class="st253" x1="263.2" y1="227.2" x2="284.1" y2="252.2"></line>
                            <line class="st254" x1="263.4" y1="227.7" x2="284.7" y2="252.2"></line>
                            <line class="st255" x1="263.6" y1="228.3" x2="285.2" y2="252.2"></line>
                            <line class="st256" x1="263.8" y1="228.9" x2="285.8" y2="252.2"></line>
                            <line class="st257" x1="264" y1="229.4" x2="286.4" y2="252.2"></line>
                            <line class="st258" x1="264.2" y1="230" x2="286.9" y2="252.2"></line>
                            <line class="st258" x1="264.5" y1="230.6" x2="287.5" y2="252.2"></line>
                            <line class="st259" x1="264.7" y1="231.1" x2="288.1" y2="252.2"></line>
                            <line class="st260" x1="264.9" y1="231.7" x2="288.7" y2="252.2"></line>
                            <line class="st261" x1="265.1" y1="232.3" x2="289.2" y2="252.2"></line>
                            <line class="st262" x1="265.3" y1="232.9" x2="289.8" y2="252.2"></line>
                            <line class="st263" x1="265.5" y1="233.4" x2="290.4" y2="252.2"></line>
                            <line class="st264" x1="265.7" y1="234" x2="290.9" y2="252.2"></line>
                            <line class="st265" x1="265.9" y1="234.6" x2="291.5" y2="252.2"></line>
                            <line class="st266" x1="266.1" y1="235.1" x2="292.1" y2="252.2"></line>
                            <line class="st267" x1="266.3" y1="235.7" x2="292.6" y2="252.2"></line>
                            <line class="st268" x1="266.5" y1="236.3" x2="293.2" y2="252.2"></line>
                            <line class="st269" x1="266.7" y1="236.8" x2="293.8" y2="252.2"></line>
                            <line class="st269" x1="266.9" y1="237.4" x2="294.4" y2="252.2"></line>
                            <line class="st270" x1="267.1" y1="238" x2="294.9" y2="252.2"></line>
                            <line class="st271" x1="267.3" y1="238.6" x2="295.5" y2="252.2"></line>
                            <line class="st272" x1="267.5" y1="239.1" x2="296.1" y2="252.2"></line>
                            <line class="st273" x1="267.7" y1="239.7" x2="296.6" y2="252.2"></line>
                            <line class="st274" x1="267.9" y1="240.3" x2="297.2" y2="252.2"></line>
                            <line class="st274" x1="268.1" y1="240.8" x2="297.8" y2="252.2"></line>
                            <line class="st275" x1="268.3" y1="241.4" x2="298.4" y2="252.2"></line>
                            <line class="st276" x1="268.5" y1="242" x2="298.9" y2="252.2"></line>
                            <line class="st277" x1="268.7" y1="242.6" x2="299.5" y2="252.2"></line>
                            <line class="st278" x1="268.9" y1="243.1" x2="300.1" y2="252.2"></line>
                            <line class="st279" x1="269.1" y1="243.7" x2="300.6" y2="252.2"></line>
                            <line class="st279" x1="269.4" y1="244.3" x2="301.2" y2="252.2"></line>
                            <line class="st280" x1="269.6" y1="244.8" x2="301.8" y2="252.2"></line>
                            <line class="st281" x1="269.8" y1="245.4" x2="302.3" y2="252.2"></line>
                            <line class="st282" x1="270" y1="246" x2="302.9" y2="252.2"></line>
                            <line class="st283" x1="270.2" y1="246.5" x2="303.5" y2="252.2"></line>
                            <line class="st284" x1="270.4" y1="247.1" x2="304.1" y2="252.2"></line>
                            <line class="st284" x1="270.6" y1="247.7" x2="304.6" y2="252.2"></line>
                            <line class="st285" x1="270.8" y1="248.3" x2="305.2" y2="252.2"></line>
                            <line class="st286" x1="271" y1="248.8" x2="305.8" y2="252.2"></line>
                            <line class="st287" x1="271.2" y1="249.4" x2="306.3" y2="252.2"></line>
                            <line class="st288" x1="271.4" y1="250" x2="306.9" y2="252.2"></line>
                            <line class="st289" x1="271.6" y1="250.5" x2="307.5" y2="252.2"></line>
                            <line class="st290" x1="271.8" y1="251.1" x2="308" y2="252.2"></line>
                            <line class="st291" x1="272" y1="251.7" x2="308.6" y2="252.2"></line>
                            <g>
                                <line class="st204" x1="251.8" y1="309.3" x2="252.2" y2="252.2"></line>
                                <line class="st205" x1="252" y1="308.7" x2="252.7" y2="252.2"></line>
                                <line class="st206" x1="252.2" y1="308.1" x2="253.3" y2="252.2"></line>
                                <line class="st207" x1="252.4" y1="307.6" x2="253.9" y2="252.2"></line>
                                <line class="st208" x1="252.6" y1="307" x2="254.4" y2="252.2"></line>
                                <line class="st209" x1="252.8" y1="306.4" x2="255" y2="252.2"></line>
                                <line class="st210" x1="253" y1="305.9" x2="255.6" y2="252.2"></line>
                                <line class="st211" x1="253.2" y1="305.3" x2="256.1" y2="252.2"></line>
                                <line class="st211" x1="253.4" y1="304.7" x2="256.7" y2="252.2"></line>
                                <line class="st212" x1="253.6" y1="304.1" x2="257.3" y2="252.2"></line>
                                <line class="st213" x1="253.8" y1="303.6" x2="257.9" y2="252.2"></line>
                                <line class="st214" x1="254" y1="303" x2="258.4" y2="252.2"></line>
                                <line class="st215" x1="254.2" y1="302.4" x2="259" y2="252.2"></line>
                                <line class="st216" x1="254.4" y1="301.9" x2="259.6" y2="252.2"></line>
                                <line class="st216" x1="254.6" y1="301.3" x2="260.1" y2="252.2"></line>
                                <line class="st217" x1="254.9" y1="300.7" x2="260.7" y2="252.2"></line>
                                <line class="st218" x1="255.1" y1="300.2" x2="261.3" y2="252.2"></line>
                                <line class="st219" x1="255.3" y1="299.6" x2="261.9" y2="252.2"></line>
                                <line class="st220" x1="255.5" y1="299" x2="262.4" y2="252.2"></line>
                                <line class="st221" x1="255.7" y1="298.4" x2="263" y2="252.2"></line>
                                <line class="st221" x1="255.9" y1="297.9" x2="263.6" y2="252.2"></line>
                                <line class="st222" x1="256.1" y1="297.3" x2="264.1" y2="252.2"></line>
                                <line class="st223" x1="256.3" y1="296.7" x2="264.7" y2="252.2"></line>
                                <line class="st224" x1="256.5" y1="296.2" x2="265.3" y2="252.2"></line>
                                <line class="st225" x1="256.7" y1="295.6" x2="265.8" y2="252.2"></line>
                                <line class="st226" x1="256.9" y1="295" x2="266.4" y2="252.2"></line>
                                <line class="st226" x1="257.1" y1="294.4" x2="267" y2="252.2"></line>
                                <line class="st227" x1="257.3" y1="293.9" x2="267.6" y2="252.2"></line>
                                <line class="st228" x1="257.5" y1="293.3" x2="268.1" y2="252.2"></line>
                                <line class="st229" x1="257.7" y1="292.7" x2="268.7" y2="252.2"></line>
                                <line class="st230" x1="257.9" y1="292.2" x2="269.3" y2="252.2"></line>
                                <line class="st231" x1="258.1" y1="291.6" x2="269.8" y2="252.2"></line>
                                <line class="st232" x1="258.3" y1="291" x2="270.4" y2="252.2"></line>
                                <line class="st233" x1="258.5" y1="290.5" x2="271" y2="252.2"></line>
                                <line class="st234" x1="258.7" y1="289.9" x2="271.5" y2="252.2"></line>
                                <line class="st235" x1="258.9" y1="289.3" x2="272.1" y2="252.2"></line>
                                <line class="st236" x1="259.1" y1="288.7" x2="272.7" y2="252.2"></line>
                                <line class="st237" x1="259.3" y1="288.2" x2="273.3" y2="252.2"></line>
                                <line class="st237" x1="259.5" y1="287.6" x2="273.8" y2="252.2"></line>
                                <line class="st238" x1="259.8" y1="287" x2="274.4" y2="252.2"></line>
                                <line class="st239" x1="260" y1="286.5" x2="275" y2="252.2"></line>
                                <line class="st240" x1="260.2" y1="285.9" x2="275.5" y2="252.2"></line>
                                <line class="st241" x1="260.4" y1="285.3" x2="276.1" y2="252.2"></line>
                                <line class="st242" x1="260.6" y1="284.8" x2="276.7" y2="252.2"></line>
                                <line class="st242" x1="260.8" y1="284.2" x2="277.2" y2="252.2"></line>
                                <line class="st243" x1="261" y1="283.6" x2="277.8" y2="252.2"></line>
                                <line class="st244" x1="261.2" y1="283" x2="278.4" y2="252.2"></line>
                                <line class="st245" x1="261.4" y1="282.5" x2="279" y2="252.2"></line>
                                <line class="st246" x1="261.6" y1="281.9" x2="279.5" y2="252.2"></line>
                                <line class="st247" x1="261.8" y1="281.3" x2="280.1" y2="252.2"></line>
                                <line class="st248" x1="262" y1="280.8" x2="280.7" y2="252.2"></line>
                                <line class="st249" x1="262.2" y1="280.2" x2="281.2" y2="252.2"></line>
                                <line class="st250" x1="262.4" y1="279.6" x2="281.8" y2="252.2"></line>
                                <line class="st251" x1="262.6" y1="279" x2="282.4" y2="252.2"></line>
                                <line class="st252" x1="262.8" y1="278.5" x2="283" y2="252.2"></line>
                                <line class="st253" x1="263" y1="277.9" x2="283.5" y2="252.2"></line>
                                <line class="st253" x1="263.2" y1="277.3" x2="284.1" y2="252.2"></line>
                                <line class="st254" x1="263.4" y1="276.8" x2="284.7" y2="252.2"></line>
                                <line class="st255" x1="263.6" y1="276.2" x2="285.2" y2="252.2"></line>
                                <line class="st256" x1="263.8" y1="275.6" x2="285.8" y2="252.2"></line>
                                <line class="st257" x1="264" y1="275.1" x2="286.4" y2="252.2"></line>
                                <line class="st258" x1="264.2" y1="274.5" x2="286.9" y2="252.2"></line>
                                <line class="st258" x1="264.5" y1="273.9" x2="287.5" y2="252.2"></line>
                                <line class="st259" x1="264.7" y1="273.3" x2="288.1" y2="252.2"></line>
                                <line class="st260" x1="264.9" y1="272.8" x2="288.7" y2="252.2"></line>
                                <line class="st261" x1="265.1" y1="272.2" x2="289.2" y2="252.2"></line>
                                <line class="st262" x1="265.3" y1="271.6" x2="289.8" y2="252.2"></line>
                                <line class="st263" x1="265.5" y1="271.1" x2="290.4" y2="252.2"></line>
                                <line class="st264" x1="265.7" y1="270.5" x2="290.9" y2="252.2"></line>
                                <line class="st265" x1="265.9" y1="269.9" x2="291.5" y2="252.2"></line>
                                <line class="st266" x1="266.1" y1="269.4" x2="292.1" y2="252.2"></line>
                                <line class="st267" x1="266.3" y1="268.8" x2="292.6" y2="252.2"></line>
                                <line class="st268" x1="266.5" y1="268.2" x2="293.2" y2="252.2"></line>
                                <line class="st269" x1="266.7" y1="267.6" x2="293.8" y2="252.2"></line>
                                <line class="st269" x1="266.9" y1="267.1" x2="294.4" y2="252.2"></line>
                                <line class="st270" x1="267.1" y1="266.5" x2="294.9" y2="252.2"></line>
                                <line class="st271" x1="267.3" y1="265.9" x2="295.5" y2="252.2"></line>
                                <line class="st272" x1="267.5" y1="265.4" x2="296.1" y2="252.2"></line>
                                <line class="st273" x1="267.7" y1="264.8" x2="296.6" y2="252.2"></line>
                                <line class="st274" x1="267.9" y1="264.2" x2="297.2" y2="252.2"></line>
                                <line class="st274" x1="268.1" y1="263.7" x2="297.8" y2="252.2"></line>
                                <line class="st275" x1="268.3" y1="263.1" x2="298.4" y2="252.2"></line>
                                <line class="st276" x1="268.5" y1="262.5" x2="298.9" y2="252.2"></line>
                                <line class="st277" x1="268.7" y1="261.9" x2="299.5" y2="252.2"></line>
                                <line class="st278" x1="268.9" y1="261.4" x2="300.1" y2="252.2"></line>
                                <line class="st279" x1="269.1" y1="260.8" x2="300.6" y2="252.2"></line>
                                <line class="st279" x1="269.4" y1="260.2" x2="301.2" y2="252.2"></line>
                                <line class="st280" x1="269.6" y1="259.7" x2="301.8" y2="252.2"></line>
                                <line class="st281" x1="269.8" y1="259.1" x2="302.3" y2="252.2"></line>
                                <line class="st282" x1="270" y1="258.5" x2="302.9" y2="252.2"></line>
                                <line class="st283" x1="270.2" y1="257.9" x2="303.5" y2="252.2"></line>
                                <line class="st284" x1="270.4" y1="257.4" x2="304.1" y2="252.2"></line>
                                <line class="st284" x1="270.6" y1="256.8" x2="304.6" y2="252.2"></line>
                                <line class="st285" x1="270.8" y1="256.2" x2="305.2" y2="252.2"></line>
                                <line class="st286" x1="271" y1="255.7" x2="305.8" y2="252.2"></line>
                                <line class="st287" x1="271.2" y1="255.1" x2="306.3" y2="252.2"></line>
                                <line class="st288" x1="271.4" y1="254.5" x2="306.9" y2="252.2"></line>
                                <line class="st289" x1="271.6" y1="254" x2="307.5" y2="252.2"></line>
                                <line class="st290" x1="271.8" y1="253.4" x2="308" y2="252.2"></line>
                                <line class="st291" x1="272" y1="252.8" x2="308.6" y2="252.2"></line>
                            </g>
                            <line class="st393" x1="194" y1="252.2" x2="231" y2="252.2"></line>
                            <line class="st392" x1="309.2" y1="252.2" x2="272.2" y2="252.2"></line>
                        </g>
                    </g>
                </svg>

			</div>
      </div>
    </>
  );
};

function getPosition(element) {
  var xPosition = 0;
  var yPosition = 0;

  while (element) {
    xPosition += element.offsetLeft - element.scrollLeft + element.clientLeft;
    yPosition += element.offsetTop - element.scrollTop + element.clientTop;
    element = element.offsetParent;
  }

  return { x: xPosition, y: yPosition };
}

export default Hero;
