import React from 'react';
import { FooterElement, CurrentDate, Signature, Facebook, Contact } from './FooterStyles';

const Footer = () => {
    var today = new Date();
    var dateVal = today.getDate();
    if (dateVal < 10) {
        dateVal = '0'+dateVal;
    }
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + dateVal;
    // var formatDate = today.toLocaleDateString("en-US");
    
    return (
        <FooterElement>
            <CurrentDate>© {date}</CurrentDate>
            <Signature>siuex software solutions</Signature>
            <Facebook>Facebook</Facebook>
            <Contact>Contact</Contact>
            
        </FooterElement>
    )
}

export default Footer
