import styled from "styled-components";

export const MainWrap = styled.div`
  justify-content: center;
  overflow: hidden;
  position: relative;
  z-index: 1;
`;

export const BackGround = styled.div`
  background: url(${({image})=>(image)}) no-repeat;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  right: 0;
  z-index: -199;
  background-size: cover;
  /* ${props => props.endAnimation &&  'animation: startBackImgAnime 2s forwards'}; 
  ${props => !props.endAnimation &&  'animation: endBackImgAnime 2s forwards'};  */
  

`;

export const BackGroundFilter = styled.div`
  background-color: rgb(0, 0, 0);
  opacity: 0.6;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  right: 0;
  z-index: -99;
  background-size: cover; 
`;
