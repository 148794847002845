import styled from "styled-components";

export const HeroContainer = styled.div`
  color: #fff;
  /* background: ${({ lightBg }) => (lightBg ? "#f9f9f9" : "#010606")}; */
  background: transparent;
  @media screen and (max-width: 768px) {
    padding: 100px 0;
  }
`;

export const HeroWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: 100vh;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
`;

export const HeroRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2  col1'` : `'col1 col2'`};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `"col1 col2"` : `'col1 col1' 'col2 col2'`};
  }
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
`;

export const TopLine = styled.p`
  color: #01bf71;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: #fff;

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ darkText }) => (darkText ? "#010606" : "#fff")};
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const ImageWrap = styled.div`
  max-width: 555px;
  height: 100%;
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`;

export const ParaWrap = styled.div`
  left: 0;
  width: 50%;
  color: white;
  top: 50vh;
  position: absolute;
  border: solid 1px white;
  transform: scale(0);
  transition: transform ease-out 150ms, left ease-out 150ms;
  transition: ${({ loadEffects }) => loadEffects && "all 350ms"};
  transform: ${({ loadEffects }) => loadEffects && "scale(1)"};
`;

export const ConsultButtonWrap = styled.div`
  left: 40vh;
  top: 70vh;
  display: flex;
  position: absolute;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  transform: scale(0.5);
  transition: all 0.2s;

  &:hover {
    transform: scale(0.55);
  }
  
`;
export const ConsultButton = styled.button`
display: block;
border: 0;
font-size: 16px;
  color: black;
  background: #1bc7d2;
  border-radius: 30%;
  
`;

export const Anim = styled.div`
  width: 530px;
  height: 625px;
  background-image: url("power.png");
  position: absolute;
  bottom: -100%;
  left: 30%;
  animation: anime 2s forwards, anime2 3s forwards 3s;

  &::after {
    content: "";
    position: absolute;
    width: 530px;
    height: 625px;
    background-image: url("power2.png");
    left: 0px;
    z-index: -1;
  }
`;
