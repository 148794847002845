import React from "react";
import { Button } from "../UI/Buttons/ButtonStyle1/Button";
import {AboutObj} from '../Data';
import {
  AboutContainer,
  AboutWrapper,
  AboutRow,
  Column1,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  Column2,
  ImageWrap,
  Img,
  
} from "./AboutStyles";

const About = () => {

  const {lightBg,
    id,
    imgStart,
    topLine,
    lightText,
    headLine,
    darkText,
    description,
    buttonLabel,
    img,
    alt,
    primary,
    dark,
    dark2 } = AboutObj;
  return (
    <>
      <AboutContainer lightBg={lightBg} id={id}>
        <AboutWrapper>
          <AboutRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>Sorry! This section is under construction.</Heading>
                {/* <Subtitle darkText={darkText}>{description}</Subtitle> */}
                
              </TextWrapper>
            </Column1>
            <Column2>
              <ImageWrap>
                <Img src={img} alt={alt} />
              </ImageWrap>
            </Column2>
          </AboutRow>
        </AboutWrapper>
      </AboutContainer>
    </>
  );
};

export default About;
