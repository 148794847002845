import React, { useState, useRef, useEffect } from "react";
import hoverEffect from "hover-effect";
import "./LiquidImage.css";
import distortion from "./distortion.png";


const LiquidImage = (props) => {
  const distortionRef = useRef();
  const buttonRef = useRef();
  var animation = null;
  

  useEffect(() => {
    animation = new hoverEffect({
      parent: distortionRef.current,
      intensity: 0.2,
      image1: props.img1,
      image2: props.img2,
      
      displacementImage: distortion,
    });

  }, [animation]);

 

  return (
    <div ref={distortionRef} class="distortion"></div>
  );
};

export default LiquidImage;
