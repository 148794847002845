import React from "react";
// import DotLoader from "react-spinners/DotLoader";
import classes from "../../../css/LoadingScreen.module.css";

const LoadingScreen = () => {
  return (
    <div className={classes.loading}>
      <div className={classes.loaderCover}/>
        <div className={classes.loader}>
          <div class={classes.bar}>
            <div class={classes.circle}></div>
            <p class={classes.p}>Loading</p>
          </div>
        </div>
        {/* <DotLoader color={"white"} loading={loading} size={150} /> */}
    </div>
  );
};

export default LoadingScreen;
