import { useState, useEffect } from "react";
import Nav from "../components/UI/Nav/Nav";
import Hero from "../components/Hero/Hero";
import { MainWrap, BackGroundFilter } from "./HomePageStyles.js";
// import ModeButton from "./components/UI/Buttons/ModeButton";
import LoadingScreen from "../components/UI/LoadingScreen/LoadingScreen";
import Menu from "../components/UI/Menu/Menu";
import Projects from "../components/Projects/Projects";
import Products from "../components/Products/Products";
// import BackGroundCanvas from "./BackGroundCanvas";
import Services from "../components/Services/Services";
import About from "../components/About/About";
import Contact from "../components/Contact/Contact";
import Landing from "./BackGroundCanvas/components/landing/Landing";
// import {Slider} from "./BackGroundCanvas/components/slider/Slider";
import Footer from "../components/UI/Footer/Footer";
import ConsultancyForm from "../components/UI/Forms/ConsultancyForm/ConsultancyForm";

const HomePage = () => {
  const [loading, setLoading] = useState(false);
  const [loadEffects, setLoadEffects] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [consultancyBtnClicked, setConsultancyBtnClicked] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      setLoadEffects(true);
      // }, 6000);
    }, 6000);
  }, []);


  const onConsultancyButtonClicked = () => {
    setConsultancyBtnClicked(!consultancyBtnClicked);
  }
  return (
    <div className="app">
      {loading ? (
        <LoadingScreen />
      ) : (
        <div>
          {/* <BackGroundCanvas/> */}
          <Landing />
          <BackGroundFilter />
          <MainWrap>
            <Nav toggle={toggle} />
            {/* <ModeButton/> */}
            {/* <div style={{ height: 1300 }}></div> */}
            <Menu />
            {consultancyBtnClicked && <ConsultancyForm onClick={onConsultancyButtonClicked}/>}
            <Hero consultancyButtonClicked={onConsultancyButtonClicked} loadEffects={loadEffects} />
            <Products />
            <Services />
            <About />
            <Projects />
            <Contact />
            <Footer />
          </MainWrap>
        </div>
      )}
    </div>
  );
};

export default HomePage;
