import styled from "styled-components";

export const MainWrap = styled.div`
height: 100vh;
  display: flex;
`;

export const SliderCSS = styled.div`
  position: relative;
  height: 71vh;
  width: 80%;
  margin: auto;
  overflow: hidden;
  /* margin-top: 0px; */
  /* font-family: sans-serif; */
  z-index: 6; 
  /* transition: height 2s; */
  /* ${({clicked}) => (clicked && 'animation: blinker 2s linear infinite;')} */
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
 
`;
export const Fader = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 10rem;
  background-image: linear-gradient(
    180deg,
    transparent,
    rgba(37, 37, 37, 0.61)
  );
`;

export const AnimationOverlay = styled.div`
  position: absolute;
  bottom: 0px;
  width: 400px;
  height: 400px;
  background-color: "blue";
`;
