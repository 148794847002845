import styled, { keyframes } from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";


export const Nav = styled.nav`
  /* background: black; */
  background: transparent;
  height: 60px;
  /* margin-top: -80px; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;

  top: 0;
  z-index: 10;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

export const NavbarContainer = styled.div`
  /* background-color: black; */
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;

  position: fixed;
  padding: 0 24px;
  max-width: 1100px;
`;

export const NavLogo = styled(LinkR)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 4px;
  font-weight: bold;
  text-decoration: none;
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 720px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  /* background-color: black; */
  list-style: none;
  text-align: center;
  margin-right: -82px;

  @media screen and (max-width: 720px) {
    display: none;
  }
`;

export const NavItem = styled(LinkS)`
  height: 70px;
  width: 115px;
`;

export const NavLinks = styled(LinkS)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0 1rem;
  margin-top: 12px;
  height: 60%;
  cursor: pointer;

  &.active {
    border-bottom: 3px solid #fff;
  }
`;


export const NavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtnLink = styled(LinkR)`
  border-radius: 50px;
  background: #01bf71;
  white-space: nowrap;
  padding: 10px 27px;
  color: #010606;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
`;

export const NavLogoImg = styled.img`
  /* z-index: 999; */
  /* position: fixed; */
  margin-top: 20px;
  margin-left: -69px;
  left: 0;
  width: 220px;
  object-fit: contain;
`;

const draw = keyframes`
0% {
    stroke-dasharray: 140 540;
    stroke-dashoffset: -215;
    stroke-width: 8px;
  }
  50% {
    stroke: #64b1b1;
    stroke-width: 6px;
  }
  50% {
    stroke: #47d434;
    stroke-width: 4px;
  }
  100% {
    stroke-dasharray: 760;
    stroke-dashoffset: 0;
    stroke-width: 2px;
    stroke: #1ac6d3;
  }
`;

export const NavText = styled(LinkS)`
height: 60px;
  width: 150px;
  color: #fff;
  /* align-items: center; */
  /* text-decoration: none; */
  /* font-family: 'Roboto Condensed'; */
  font-size: 18px;
  padding-left: 30px;  
  letter-spacing: 1px;
  line-height: 22px;
  text-transform: uppercase;
  /* width: 140px; */
  position: relative;
  top: -46px;

  &.active {
    color: #d3922f;
    /* color: #1ac6d3; */
  }

`;
export const Rect = styled.rect`
  cursor: pointer;
  height: 60px;
  width: 150px;
  fill: transparent; 

  stroke-dasharray: 140 540;
  stroke-dashoffset: -215;
  /* stroke-dasharray: 140 540;
  stroke-dashoffset: -289; */
  stroke-width: 5px;
  stroke: #ffffff;
  

  -webkit-animation-name: 0.5s ${draw} linear forwards;
  animation-name: 0.5s ${draw} linear forwards;
/* 
  
  ${NavText}:active & {
    fill: transparent;
  } */
`;


export const SvgWrapper = styled.div`
cursor: pointer;
  height: 60px;
  margin: 0;
  position: relative;
  top: 20%;
  transform: translateY(-50%);
  transform: scale(0.8);
  transition: transform ease-out 150ms, left ease-out 150ms;

  &:hover ${Rect}{
    -webkit-animation: 0.5s draw linear forwards;
    animation: 0.5s draw linear forwards;
  }
`;

export const Svg = styled.svg`
  height: 60px;
  width: 150px;
`;


