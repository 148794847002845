import React, { useState, useEffect, useRef, useCallback } from "react";
import SliderContent from "./SliderContent";
import Arrow from "./Arrow";
import Dots from "./Dots";
import {
  ProductItemWrapper,
  ProductItemDetails,
  ProductItemImage,
  Heading,
  Paragraph,
  Title,
} from "./ProductSliderStyles";
import {
  SliderCSS,
  MainWrap,
} from "../UI/SlideComponent/SliderStyles";
import LiquidImage from "./LiquidImage";
import custom1 from "../../assets/LiquidImages/custom1.jpg";
import custom2 from "../../assets/LiquidImages/custom2.jpg";
import website1 from "../../assets/LiquidImages/web1.jpg";
import website2 from "../../assets/LiquidImages/web2.jpg";
import android1 from "../../assets/LiquidImages/android1.jpg";
import android2 from "../../assets/LiquidImages/android2.jpg";
import ai1 from "../../assets/LiquidImages/ai1.jpg";
import ai2 from "../../assets/LiquidImages/ai2.jpg";
import AOS from "aos";
import "aos/dist/aos.css";

const ProductsSlider = (props) => {
  const autoPlayRef = useRef();
  const [navColor, setNavColor] = useState("");
  const [clicked, setClicked] = useState(false);
  const [stopAutoSlide, setStopAutoSlide] = useState(false);
  const getWidth = () => window.innerWidth;

  var curSlide = "";

  AOS.init();
  const [state, setState] = useState({
    activeIndex: 0,
    translate: 0,
    transition: 0.45,
  });

  const { translate, transition, activeIndex } = state;

  const nextSlide = useCallback(() => {
    if (activeIndex === props.slides.length - 1) {
      return setState({
        ...state,
        translate: 0,
        activeIndex: 0,
      });
    }

    setState({
      ...state,
      activeIndex: activeIndex + 1,
      translate: (activeIndex + 1) * getWidth(),
    });
  }, [activeIndex, props.slides.length, state]);

  const prevSlide = useCallback(() => {
    if (activeIndex === 0) {
      return setState({
        ...state,
        translate: (props.slides.length - 1) * getWidth(),
        activeIndex: props.slides.length - 1,
      });
    }

    setState({
      ...state,
      activeIndex: activeIndex - 1,
      translate: (activeIndex - 1) * getWidth(),
    });
  }, [activeIndex, props.slides.length, state]);

  // useEffect(() => {
  //   autoPlayRef.current = nextSlide;
  // });

  // useEffect(() => {
  //   if (!stopAutoSlide) {
  //     setClicked(true);
  //   const play = () => {
  //     autoPlayRef.current();

  //   };
  //   setTimeout(() => {
  //     setClicked(false);
  //   }, 2000);

  //   const interval = setInterval(play, 2000);
  //   return () => clearInterval(interval);
  //   }
  // }, [nextSlide, prevSlide]);

  const filteredSlide = props.slides.filter((data) => {
    return data.index === activeIndex;
  });
  const SlidesData = filteredSlide.map((data, index) => {
    curSlide = data.navColor;
    return { curSlide };
  });

  const stopAutoSlideHandler = (event) => {
    setStopAutoSlide(true);
  };

  const startAutoSlideHandler = (event) => {
    setStopAutoSlide(false);
  };
  return (
    <div>
      <Title>Our Products</Title>
      <MainWrap>
        <SliderCSS clicked={clicked}>
          <SliderContent
            translate={translate}
            transition={transition}
            width={getWidth() * props.slides.length}
          >
            <ProductItemWrapper
              left={"229"}
              data-aos="flip-up"
              data-aos-duration="2000"
            >
              <ProductItemDetails color={curSlide}>
                <Heading left={"4"}>Customized Development</Heading>
                <Paragraph>
                  We'll make your idea become accessible to large audiences or
                  for a certain group of your preference contact us today and
                  lets talk about how to grow your business.
                </Paragraph>
              </ProductItemDetails>
              <ProductItemImage>
                <LiquidImage img1={custom1} img2={custom2} />
              </ProductItemImage>
            </ProductItemWrapper>
            <ProductItemWrapper
              left={"1595"}
              data-aos="fade-down"
              data-aos-duration="2000"
            >
              <ProductItemDetails color={curSlide}>
                <Heading>Build an website</Heading>
                <Paragraph>
                  Specializing in creating e-commerce, blogs, landing pages our
                  professionals can offer you a great product.
                </Paragraph>
              </ProductItemDetails>
              <ProductItemImage>
                <LiquidImage img1={website1} img2={website2} />
              </ProductItemImage>
            </ProductItemWrapper>
            <ProductItemWrapper
              left={"2960"}
              data-aos="left-in"
              data-aos-duration="2000"
            >
              <ProductItemDetails color={curSlide}>
                <Heading>Android or IOS apps</Heading>
                <Paragraph>
                  Create an application with android, ios or with both operating
                  systems and if you have the requirement we can integrate it
                  with a web application to suit your business.
                </Paragraph>
              </ProductItemDetails>
              <ProductItemImage>
                <LiquidImage img1={android1} img2={android2} />
              </ProductItemImage>
            </ProductItemWrapper>
            <ProductItemWrapper
              left={"4330"}
              data-aos="zoom-in-up"
              data-aos-duration="2000"
            >
              <ProductItemDetails color={curSlide}>
                <Heading>AI Features</Heading>
                <Paragraph>
                  We can apply object recognition and various machine learning
                  features to your applications activities that might prove
                  helpful to you.
                </Paragraph>
              </ProductItemDetails>
              <ProductItemImage>
                <LiquidImage img1={ai1} img2={ai2} />
              </ProductItemImage>
            </ProductItemWrapper>
          </SliderContent>
          {/* {props.slides.map((slide, i) => (
         
        ))} */}

          {/* <Fader /> */}
          <Arrow
          
            prevSlide={prevSlide}
            nextSlide={nextSlide}
            navColor={curSlide}
          />
        
        <Dots
            slides={props.slides}
            activeIndex={activeIndex}
            navColor={curSlide}
          />
        </SliderCSS>
      </MainWrap>
    </div>
  );
};

export default ProductsSlider;
