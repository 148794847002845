import styled from "styled-components";

export const NavCss = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 2;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  .ty-seqHomeSlider-nav button {
    outline: none;
  }
`;

export const NextCss = styled.button`
  outline: none;
  position: relative;
  background: none;
  border: none;
  font-weight: normal;
  font-size: 3.5em;
  display: block;
  color: #1c1c1c;
  padding-right: 5px;
  padding-left: 10px;
  float: right;
  &:after,
  &:before {
    content: "";
    position: absolute;
    background: ${(props) => props.navColor};
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  &:after {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }
  &:before {
    width: 3px;
    height: 400%;
    top: -150%;
    -webkit-transition-delay: 0.3s;
    -moz-transition-delay: 0.3s;
    -ms-transition-delay: 0.3s;
    -o-transition-delay: 0.3s;
    transition-delay: 0.3s;
  }
  &:before {
    right: 0;
  }
  &:after {
    -webkit-transform-origin: 100% 50%;
    -moz-transform-origin: 100% 50%;
    -ms-transform-origin: 100% 50%;
    -o-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
  }

  & {
      color: #a9a9a9;
    }
    &:after {
      -webkit-transform: scale(0, 1);
      -moz-transform: scale(0, 1);
      -ms-transform: scale(0, 1);
      -o-transform: scale(0, 1);
      transform: scale(0, 1);
    }
    &:before {
      -webkit-transform: scale(0, 0);
      -moz-transform: scale(0, 0);
      -ms-transform: scale(0, 0);
      -o-transform: scale(0, 0);
      transform: scale(0, 0);
    }
    &:hover {
      color: #1c1c1c;
    }
    &:hover:before {
      -webkit-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      -o-transform: scale(1, 1);
      transform: scale(1, 1);
    }
    &:hover:after {
      -webkit-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      -o-transform: scale(1, 1);
      transform: scale(1, 1);
    }
  @media (min-width: 1170px) {
    & {
      color: #a9a9a9;
    }
    &:after {
      -webkit-transform: scale(0, 1);
      -moz-transform: scale(0, 1);
      -ms-transform: scale(0, 1);
      -o-transform: scale(0, 1);
      transform: scale(0, 1);
    }
    &:before {
      -webkit-transform: scale(0, 0);
      -moz-transform: scale(0, 0);
      -ms-transform: scale(0, 0);
      -o-transform: scale(0, 0);
      transform: scale(0, 0);
    }
    &:hover {
      color: #1c1c1c;
    }
    &:hover:before {
      -webkit-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      -o-transform: scale(1, 1);
      transform: scale(1, 1);
    }
    &:hover:after {
      -webkit-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      -o-transform: scale(1, 1);
      transform: scale(1, 1);
    }
  }
`;

export const PrevCss = styled.button`
  & {
    outline: none;
    position: relative;
    background: none;
    border: none;
    font-weight: normal;
    font-size: 3.5em;
    display: block;
    color: #1c1c1c;
    padding-left: 5px;
    padding-right: 10px;
    float: left;
  }
  &:after,
  &:before {
    content: "";
    position: absolute;
    background: ${(props) => props.navColor};
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  &:after {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }
  &:before {
    width: 3px;
    height: 400%;
    top: -150%;
    -webkit-transition-delay: 0.3s;
    -moz-transition-delay: 0.3s;
    -ms-transition-delay: 0.3s;
    -o-transition-delay: 0.3s;
    transition-delay: 0.3s;
  }
  &:before {
    left: 0;
  }
  &:after {
    -webkit-transform-origin: 0 50%;
    -moz-transform-origin: 0 50%;
    -ms-transform-origin: 0 50%;
    -o-transform-origin: 0 50%;
    transform-origin: 0 50%;
  }

  & {
      color: #a9a9a9;
    }
    &:after {
      -webkit-transform: scale(0, 1);
      -moz-transform: scale(0, 1);
      -ms-transform: scale(0, 1);
      -o-transform: scale(0, 1);
      transform: scale(0, 1);
    }
    &:before {
      -webkit-transform: scale(0, 0);
      -moz-transform: scale(0, 0);
      -ms-transform: scale(0, 0);
      -o-transform: scale(0, 0);
      transform: scale(0, 0);
    }
    &:hover {
      color: #1c1c1c;
    }
    &:hover:before {
      -webkit-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      -o-transform: scale(1, 1);
      transform: scale(1, 1);
    }
    &:hover:after {
      -webkit-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      -o-transform: scale(1, 1);
      transform: scale(1, 1);
    }
  @media (min-width: 1170px) {
    & {
      color: #a9a9a9;
    }
    &:after {
      -webkit-transform: scale(0, 1);
      -moz-transform: scale(0, 1);
      -ms-transform: scale(0, 1);
      -o-transform: scale(0, 1);
      transform: scale(0, 1);
    }
    &:before {
      -webkit-transform: scale(0, 0);
      -moz-transform: scale(0, 0);
      -ms-transform: scale(0, 0);
      -o-transform: scale(0, 0);
      transform: scale(0, 0);
    }
    &:hover {
      color: #1c1c1c;
    }
    &:hover:before {
      -webkit-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      -o-transform: scale(1, 1);
      transform: scale(1, 1);
    }
    &:hover:after {
      -webkit-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      -o-transform: scale(1, 1);
      transform: scale(1, 1);
    }
  }
`;
