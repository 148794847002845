export const HeroObj = {
  id: "hero",
  topLine: "Software Development",
  headLine: "Bringing Digital Excellence",
  description2:
    "Are you looking for a software solution it doesn't matter if you are a start business or personal  or website to be developed? look no more a professional will get it done for you.",
  description: "Are you looking for a software solution? Siuex offers digital software solutions ranging from start ups to businesses. let a professional build your requirement in a digital level.",
  img: require("../assets/img1.svg").default,
 
};

export const ProductsObj = {
  id: "products",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Premium Bank",
  headLine: "Unlimited Transactions with zero fees",
  description:
    "Get access to our exclusive app that allows you to send unlimited transactions without getting charged any fees.",
  buttonLabel: "Get Started",
  imgStart: false,
  img: require("../assets/img1.svg").default,
  alt: "Car",
  dark: true,
  primary: true,
  darkText: false,
};


export const ProjectsObj = {
  id: "projects",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Premium Bank",
  headLine: "Unlimited Transactions with zero fees",
  description:
    "Get access to our exclusive app that allows you to send unlimited transactions without getting charged any fees.",
  buttonLabel: "Get Started",
  imgStart: false,
  img: require("../assets/img1.svg").default,
  alt: "Car",
  dark: true,
  primary: true,
  darkText: false,
};

export const ServicesObj = {
  id: "services",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Premium Bank",
  headLine: "Unlimited Transactions with zero fees",
  description:
    "Get access to our exclusive app that allows you to send unlimited transactions without getting charged any fees.",
  buttonLabel: "Get Started",
  imgStart: false,
  img: require("../assets/img1.svg").default,
  alt: "Car",
  dark: true,
  primary: true,
  darkText: false,
};
export const AboutObj = {
  id: "about",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Premium Bank",
  headLine: "Unlimited Transactions with zero fees",
  description:
    "Get access to our exclusive app that allows you to send unlimited transactions without getting charged any fees.",
  buttonLabel: "Get Started",
  imgStart: false,
  img: require("../assets/img1.svg").default,
  alt: "Car",
  dark: false,
  primary: true,
  darkText: false,
};


export const ContactObj = {
  id: "contact",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Premium Bank",
  headLine: "Unlimited Transactions with zero fees",
  description:
    "Get access to our exclusive app that allows you to send unlimited transactions without getting charged any fees.",
  buttonLabel: "Get Started",
  imgStart: false,
  img: require("../assets/img1.svg").default,
  alt: "Car",
  dark: true,
  primary: true,
  darkText: false,
};


export const homeObjOne = {
  id: "home",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Premium Bank",
  headLine: "Unlimited Transactions with zero fees",
  description:
    "Are you looking for a software solution? we provide personalized web-sites for individuals and businesses.  let a professional build your requirement in a digital level.",
  description2: "",
  buttonLabel: "Get Started",
  imgStart: false,
  img: require("../assets/img1.svg").default,
  alt: "Car",
  dark: true,
  primary: true,
  darkText: false,
};

export const homeObjTwo = {
  id: "services",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Premium Bank",
  headLine: "Unlimited Transactions with zero fees",
  description:
    "Get access to our exclusive app that allows you to send unlimited transactions without getting charged any fees.",
  buttonLabel: "Get Started",
  imgStart: false,
  img: require("../assets/img1.svg").default,
  alt: "Car",
  dark: true,
  primary: true,
  darkText: false,
};

