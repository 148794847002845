import styled from "styled-components";

export const ProductItemWrapper = styled.div`
  align-items: center;
  top: 60px;
  left: ${(props) => props.left}px;
  width: 600px;
  height: 350px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* justify-content: center; */
  font-size: 14px;
  position: fixed;
  /* background-color: pink;  */
`;

export const ProductItemDetails = styled.div`
  grid-column-start: 0;
  grid-column-end: 1;
  width: 300px;
  height: 300px;
  align-items: center;
  background-color: ${(props) => props.color};
  transition: background-color 3s;
  text-align: center;
  border-radius: 1%;
  transform: scale(1.2);
  z-index: 1;
`;

export const Heading = styled.div`
  padding-top: 40px;
  padding-left: 1px;
  color: blue;
  font-size: 26px;
`;

export const Paragraph = styled.div`
  padding-top: 25px;
  padding-left: 3px;
  color: black;
  font-size: 16px;
`;

export const ProductItemImage = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  width: 300px;
  height: 300px;
  transform: scale(1.1);
  border-radius: 1%;
  z-index: -1;
  transition: all 0.2s;
  &:hover {
    z-index: 2;
    transform: scale(1.2);
  }
  /* background-color: green; */
`;

export const Title = styled.div`
  width: 300px;
  margin-left: 100px;
  font-size: 30px;
  margin-top: 10px;
  position: relative;
  /* background-color: green; */
`;
