import styled from "styled-components";
import { Link as LinkS } from "react-scroll";

export const Wrap = styled(LinkS)`
  width: 420px;
  height: 40px;
  padding: 0;
  margin: 0;
  top: 0;
  &.active {

  }
`;


export const Icon = styled(LinkS)`
  width: 420px;
  height: 40px;
  padding: 0;
  margin: 0;
  top: 0;
  &.active {
      
  }
`;