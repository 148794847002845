import React from "react";
import styled from "styled-components";

const CssStylesSpan = ({ active, navColor }) => {
  const StyleSpan = styled.span`
    padding: 5px;
    margin-right: 11px;
    cursor: pointer;
    border-radius: 50%;
    background: ${active ? "white" : "white"};
    opacity: 1;
    
    ${active && "outline: 1px solid white"};
    ${active && "outline-offset: 4px"};
    /* ${active && "outline-style: dashed"}; */
    
  

    &:hover {
      content: '';
      padding: 4px;
      background: ${active ? "white" : "gray"};
/*      
      outline: 1px solid white;
      outline-offset: 4px; */
    }
  `;

  return <StyleSpan />;
};

const CssStylesDiv = styled.div`
  position: absolute;
  bottom: 25px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Dots = ({ slides, activeIndex }) => {
  return (
    <CssStylesDiv>
      {slides.map((slide, i) => (
        <CssStylesSpan key={slide} active={activeIndex === i} />
      ))}
    </CssStylesDiv>
  );
};

export default Dots;
