import React, { Fragment,useState } from "react";
import "./App.css";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import HomePage from "./HomePage/HomePage";
// import KenBurns from "./components/UI/ImageBlocksAnime/kenburnesEffect/KenBurns";
// import LiquidImage from "./components/UI/ImageBlocksAnime/LiquidImage";

const App = () => {


  return (
    <Fragment>
    <Router>
       <Switch>
           <Route path="/" component={HomePage} exact />
        </Switch>
      </Router>
    </Fragment>
  );
};

export default App;
