import React from "react";
import { Button } from "../UI/Buttons/ButtonStyle1/Button";
import {ProjectsObj} from '../Data';
import {
  ProjectsContainer,
  ProjectsWrapper,
  ProjectsRow,
  Column1,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  Column2,
  ImageWrap,
  Img,
  
} from "./ProjectsStyles";

const Projects = () => {

  const {lightBg,
    id,
    imgStart,
    topLine,
    lightText,
    headLine,
    darkText,
    description,
    buttonLabel,
    img,
    alt,
    primary,
    dark,
    dark2 } = ProjectsObj;
  return (
    <>
      <ProjectsContainer lightBg={lightBg} id={id}>
        <ProjectsWrapper>
          <ProjectsRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>Sorry! This section is under construction.</Heading>
                {/* <Subtitle darkText={darkText}>{description}</Subtitle> */}
               
              </TextWrapper>
            </Column1>
            <Column2>
              <ImageWrap>
                <Img src={img} alt={alt} />
              </ImageWrap>
            </Column2>
          </ProjectsRow>
        </ProjectsWrapper>
      </ProjectsContainer>
    </>
  );
};

export default Projects;
